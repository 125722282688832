import {
  ADD_CATEGORY_FAIL,
  ADD_CATEGORY_SUCCESS,
  ADD_NEW_CATEGORY,
  GET_CATEGORYS,
  GET_CATEGORYS_FAIL,
  GET_CATEGORYS_SUCCESS
} from "./actionTypes"
  
  export const addNewCategory = (data, history, authtoken) => ({
    type: ADD_NEW_CATEGORY,
    payload: { data, history , authtoken},
  })
  
  export const addCategorySuccess = data => ({
    type: ADD_CATEGORY_SUCCESS,
    payload: data,
  })
  
  export const addCategoryFail = error => ({
    type: ADD_CATEGORY_FAIL,
    payload: error,
  })

export const getCategorys = (authtoken) => ({
  type: GET_CATEGORYS,
  payload: { authtoken }
})

export const getCategorysSuccess = data => ({
  type: GET_CATEGORYS_SUCCESS,
  payload: data,
})

export const getCategorysFail = error => ({
  type: GET_CATEGORYS_FAIL,
  payload: error,
})
