import { toast } from "react-toastify"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  addCategorydata,
  getCategorydata,
} from "../../helpers/backend_helper.js"
import { ADD_NEW_CATEGORY, GET_CATEGORYS } from "./actionTypes"
import {
  addCategoryFail,
  addCategorySuccess,
  getCategorysFail,
  getCategorysSuccess,
} from "./actions"

function* onAddCategory({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(addCategorydata, data, authtoken)
    //  console.log('hi');
    //  console.log(response);
    yield put(addCategorySuccess(response))
    toast("🦄 Category added successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    history.push("/category-list")
  } catch (error) {
    if (!error.response) {
      toast("🦄 Category added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      history.push("/category-list")
    } else {
      let message = error.response.data.message
      yield put(addCategoryFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}

function* fetchCategory({ payload: { authtoken } }) {
  try {
    //  console.log('fetched area');
    const response = yield call(getCategorydata, authtoken)
    //  console.log(response);
    yield put(getCategorysSuccess(response))
  } catch (error) {
    yield put(getCategorysFail(error))
  }
}

function* categorySaga() {
  yield takeEvery(ADD_NEW_CATEGORY, onAddCategory)
  yield takeEvery(GET_CATEGORYS, fetchCategory)
}

export default categorySaga
