import {
  ADD_BRAND_FAIL,
  ADD_NEW_BRAND,
  ADD_BRAND_SUCCESS, GET_BRANDS_FAIL,
  GET_BRANDS_SUCCESS,
  GET_BRANDS_STATUS_SUCCESS,
  GET_BRANDS_STATUS_FAIL
} from "./actionTypes"

const INIT_STATE = {
    
    brands: [],
    brandStatus: [],
    brand:{},
    error: {},
    loading: true,
    brandStatusLoading: true

  }

  const OrderReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case ADD_NEW_BRAND: {
        return {
          ...state,
          error: {},
          isLoading: true,
        }
      }
      case ADD_BRAND_SUCCESS:
            return {
              ...state,
              isLoading: false,
              brands: [...state.brands, action.payload],    
            }
      
      case ADD_BRAND_FAIL:
            return {
              ...state,
              error: action.payload,
              loading: false,
            }
      case GET_BRANDS_SUCCESS:
        return {
          ...state,
          brands: action.payload,
          loading: false,
        }
      case GET_BRANDS_FAIL:
        return {
          ...state,
          loading: true,
          error: action.payload,
        }
        case GET_BRANDS_STATUS_SUCCESS:
          return {
            ...state,
            brandStatus: action.payload,
            brandStatusLoading: false,
          }
        case GET_BRANDS_STATUS_FAIL:
          return {
            ...state,          
            error: action.payload,
            brandStatusLoading: true,
          }
      default:
        return state
    }
  }
  
export default OrderReducer