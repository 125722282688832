import {
  AREA_FILTER_CHANGED,
  CLEAR_FILTERS,
  GET_AVG_INVOICE_VALUE_FAIL,
  GET_AVG_INVOICE_VALUE_SUCCESS,
  GET_BOUNCE_DATA_FAIL,
  GET_BOUNCE_DATA_SUCCESS,
  GET_DASHBOARD_CHART_FAIL,
  GET_DASHBOARD_CHART_SUCCESS,
  GET_DASHBOARD_ORDER_SALES,
  GET_DASHBOARD_ORDER_SALES_FAIL,
  GET_DASHBOARD_ORDER_SALES_SUCCESS,
  GET_DASHBOARD_OVERVIEW_FAIL,
  GET_DASHBOARD_OVERVIEW_SUCCESS,
  GET_MAP_DATA,
  GET_MAP_DATA_FAIL,
  GET_MAP_DATA_SUCCESS,
  GET_PRE_ORDER_DELIVERY_FAIL,
  GET_PRE_ORDER_DELIVERY_SUCCESS,
  GET_REVENUE_INFO_FAIL,
  GET_REVENUE_INFO_SUCCESS,
  GET_SELLING_OVERVIEW_FAIL,
  GET_SELLING_OVERVIEW_SUCCESS,
  GET_TARGET_ANALYSIS_FAIL,
  GET_TARGET_ANALYSIS_SUCCESS,
  GET_TOP_BRAND_FAIL,
  GET_TOP_BRAND_SUCCESS,
  GET_TOP_DP_FAIL,
  GET_TOP_DP_SUCCESS,
  GET_TOP_PRODUCT_FAIL,
  GET_TOP_PRODUCT_SUCCESS,
  GET_TOP_REGION_FAIL,
  GET_TOP_REGION_SUCCESS,
  GET_TOP_SR_FAIL,
  GET_TOP_SR_SUCCESS,
  GET_TOTAL_DP_FAIL,
  GET_TOTAL_DP_SUCCESS,
  GET_TOTAL_SR_FAIL,
  GET_TOTAL_SR_SUCCESS,
  MAIN_FILTER_CHANGED,
  REGION_FILTER_CHANGED,
  STORE_DASHBOARD_DATA,
  TERRITORY_FILTER_CHANGED,
  TOGGLE_DASHBOARD_DATA,
} from "./actionTypes"
const INIT_STATE = {
  dashboardOverviews: [],
  dashboardOverview: {},
  dashboardCharts: [],
  dashboardChart: {},
  sellingOverviews: [],
  sellingOverview: {},
  topProducts: [],
  topProduct: {},
  totalDP: [],
  totalSR: [],
  topSRs: [],
  topDPs: [],
  revInfo: [],
  topBrands: [],
  BounceRate: [],
  topBrand: {},
  topRoutes: [],
  avgInvoiceValue: [],
  topregion: {},
  preOrderDelivery: [],
  targetAnalysis: [],
  chartloading: true,
  overviewLoading: true,
  topProductLoading: true,
  sellingOverviewLoading: true,
  totalSrLoading: true,
  totalDpLoading: true,
  topSrLoading: true,
  topDpLoading: true,
  topBrandLoading: true,
  topRouteLoading: true,
  preOrderDeliveryLoading: true,
  mainFilter: {},
  loadFilter: false,
  subFilters: {},
  subSelectedFilters: {},
  isSubFilterChanged: false,
  totalSrLoading: true,
  totalDpLoading: true,
  topSrLoading: true,
  topDpLoading: true,
  topBrandLoading: true,
  topRouteLoading: true,
  preOrderDeliveryLoading: true,
  bounceRateLoading: true,
  avgInvoiceValueLoading: true,
  revInfoLoading: true,
  areaList: [],
  territoryList: [],
  dashboardMap: [],
  selectedRegion: {},
  selectedArea: {},
  selectedTerritory: {},
  dashboardMapLoading: false,
  targetAnalysisLoading: true,
  currentSelection: "",
  viewFull: false,
  selectedMapType: "bp",
  currentLocation: {
    lat: 23.77340239600077,
    lng: 90.41329051290532,
  },
  zoom: 6,
  selectedLocation: null,
  isMapView: false,
  selectedStore: {},
  orderSalesLoading: false,
  orderSales: {},
  salesOrder: {},
}

const DashboardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARD_OVERVIEW_SUCCESS:
      return {
        ...state,
        dashboardOverviews: action.payload,
        overviewLoading: false,
      }
    case GET_DASHBOARD_OVERVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
        overviewLoading: true,
      }

    case MAIN_FILTER_CHANGED:
      return {
        ...state,
        mainFilter: action.payload,
        selectedRegion: {},
        selectedArea: {},
        selectedTerritory: {},
        currentSelection: "main",
        isSubFilterChanged: !state.isSubFilterChanged,
      }
    case REGION_FILTER_CHANGED:
      return {
        ...state,
        selectedRegion: action.payload,
        areaList: [],
        selectedArea: {},
        currentSelection: "region",
        isSubFilterChanged: !state.isSubFilterChanged,
      }
    case AREA_FILTER_CHANGED:
      return {
        ...state,
        selectedArea: action.payload,
        territoryList: [],
        selectedTerritory: {},
        currentSelection: "area",
        isSubFilterChanged: !state.isSubFilterChanged,
      }
    case TERRITORY_FILTER_CHANGED:
      return {
        ...state,
        selectedTerritory: action.payload,
        currentSelection: "territory",
        isSubFilterChanged: !state.isSubFilterChanged,
      }
    case CLEAR_FILTERS:
      return {
        ...state,
        mainFilter: {},
        selectedRegion: {},
        selectedArea: {},
        selectedTerritory: {},
      }

    case STORE_DASHBOARD_DATA:
      // console.log(`action.payload:`, action.payload)
      return {
        ...state,
        [action.payload.name]: action.payload.data,
        zoom: action.payload.zoom ? action.payload.zoom : state.zoom,
      }
    case TOGGLE_DASHBOARD_DATA:
      return {
        ...state,
        [action.payload.name]: !state[action.payload.name],
      }
    case GET_DASHBOARD_CHART_SUCCESS:
      return {
        ...state,
        dashboardCharts: action.payload,
        chartloading: false,
      }
    case GET_DASHBOARD_CHART_FAIL:
      return {
        ...state,
        error: action.payload,
        chartloading: true,
      }
    case GET_SELLING_OVERVIEW_SUCCESS:
      return {
        ...state,
        sellingOverviews: action.payload,
        sellingOverviewLoading: false,
      }
    case GET_SELLING_OVERVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
        sellingOverviewLoading: true,
      }
    case GET_TOP_PRODUCT_SUCCESS:
      return {
        ...state,
        topProducts: action.payload,
        topProductLoading: false,
      }
    case GET_TOP_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
        topProductLoading: true,
      }
    case GET_TOTAL_SR_SUCCESS:
      return {
        ...state,
        totalSR: action.payload,
        totalSrLoading: false,
      }
    case GET_TOTAL_SR_FAIL:
      return {
        ...state,
        error: action.payload,
        totalSrLoading: true,
      }
    case GET_TOTAL_DP_SUCCESS:
      return {
        ...state,
        totalDP: action.payload,
        totalDpLoading: false,
      }
    case GET_TOTAL_DP_FAIL:
      return {
        ...state,
        error: action.payload,
        totalDpLoading: true,
      }
    case GET_TOP_DP_SUCCESS:
      return {
        ...state,
        topDPs: action.payload,
        topDpLoading: false,
      }
    case GET_TOP_DP_FAIL:
      return {
        ...state,
        error: action.payload,
        topDpLoading: true,
      }
    case GET_TOP_SR_SUCCESS:
      return {
        ...state,
        topSRs: action.payload,
        topSrLoading: false,
      }
    case GET_TOP_SR_FAIL:
      return {
        ...state,
        error: action.payload,
        topSrLoading: true,
      }
    case GET_TOP_BRAND_SUCCESS:
      return {
        ...state,
        topBrands: action.payload,
        topBrandLoading: false,
      }
    case GET_TOP_BRAND_FAIL:
      return {
        ...state,
        error: action.payload,
        topBrandLoading: true,
      }
    case GET_TOP_REGION_SUCCESS:
      return {
        ...state,
        topRoutes: action.payload,
        topRouteLoading: false,
      }
    case GET_TOP_REGION_FAIL:
      return {
        ...state,
        error: action.payload,
        topRouteLoading: true,
      }
    case GET_TOP_REGION_SUCCESS:
      return {
        ...state,
        topRoutes: action.payload,
        topRouteLoading: false,
      }
    case GET_TOP_REGION_FAIL:
      return {
        ...state,
        error: action.payload,
        topRouteLoading: true,
      }
    case GET_PRE_ORDER_DELIVERY_SUCCESS:
      return {
        ...state,
        preOrderDelivery: action.payload,
        preOrderDeliveryLoading: false,
      }
    case GET_PRE_ORDER_DELIVERY_FAIL:
      return {
        ...state,
        error: action.payload,
        preOrderDeliveryLoading: true,
      }
    case GET_TOP_REGION_SUCCESS:
      return {
        ...state,
        topRoutes: action.payload,
        topRouteLoading: false,
      }
    case GET_TOP_REGION_FAIL:
      return {
        ...state,
        error: action.payload,
        topRouteLoading: true,
      }
    case GET_PRE_ORDER_DELIVERY_SUCCESS:
      return {
        ...state,
        preOrderDelivery: action.payload,
        preOrderDeliveryLoading: false,
      }
    case GET_PRE_ORDER_DELIVERY_FAIL:
      return {
        ...state,
        error: action.payload,
        preOrderDeliveryLoading: true,
      }
    case GET_BOUNCE_DATA_SUCCESS:
      return {
        ...state,
        BounceRate: action.payload,
        bounceRateLoading: false,
      }
    case GET_BOUNCE_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
        bounceRateLoading: true,
      }
    case GET_AVG_INVOICE_VALUE_SUCCESS:
      return {
        ...state,
        avgInvoiceValue: action.payload,
        avgInvoiceValueLoading: false,
      }
    case GET_AVG_INVOICE_VALUE_FAIL:
      return {
        ...state,
        error: action.payload,
        avgInvoiceValueLoading: true,
      }
    case GET_TARGET_ANALYSIS_SUCCESS:
      return {
        ...state,
        targetAnalysis: action.payload,
        targetAnalysisLoading: false,
      }
    case GET_TARGET_ANALYSIS_FAIL:
      return {
        ...state,
        error: action.payload,
        targetAnalysisLoading: true,
      }
    case GET_REVENUE_INFO_SUCCESS:
      return {
        ...state,
        revInfo: action.payload,
        revInfoLoading: false,
      }
    case GET_REVENUE_INFO_FAIL:
      return {
        ...state,
        error: action.payload,
        revInfoLoading: true,
      }
    case GET_MAP_DATA:
      return {
        ...state,

        dashboardMapLoading: true,
      }
    case GET_MAP_DATA_SUCCESS:
      return {
        ...state,
        dashboardMap: action.payload,
        dashboardMapLoading: false,
      }
    case GET_MAP_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
        dashboardMapLoading: false,
      }

    case GET_DASHBOARD_ORDER_SALES:
      return {
        ...state,
        orderSalesLoading: false,
      }
    case GET_DASHBOARD_ORDER_SALES_SUCCESS:
      const forData = action.payload?.data?.forData

      if(forData === "order"){
        return {
          ...state,
          orderSalesLoading: false,
          orderSales:  action.payload?.data,
        }
      } else if(forData === "sales") {
        return {
          ...state,
          orderSalesLoading: false,
          salesOrder:  action.payload?.data,
        }
      }
      
    case GET_DASHBOARD_ORDER_SALES_FAIL:
      return {
        ...state,
        error: action.payload,
        orderSalesLoading: false,
      }
    default:
      return state
  }
}

export default DashboardReducer
