import {

  ADD_CATEGORY_FAIL,
  ADD_CATEGORY_SUCCESS, GET_CATEGORYS_FAIL,
  GET_CATEGORYS_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    
    categories: [],
    category:{},
    error: {},
    loading: true,
    categoryLoading:false,

  }

  const CategoryReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case ADD_CATEGORY_SUCCESS:
            return {
              ...state,
              loading: false,
              categories: [...state.categories, action.payload],    
            }
      
      case ADD_CATEGORY_FAIL:
            return {
              ...state,
              error: action.payload,
              loading: false,
            }
      case GET_CATEGORYS_SUCCESS:
        return {
          ...state,
          categories: action.payload,
          loading: false,
          categoryLoading: true,
        }
      case GET_CATEGORYS_FAIL:
        return {
          ...state,
          loading: true,
          error: action.payload,
        categoryLoading: false,

        }
      default:
        return state
    }
  }
  
export default CategoryReducer