import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
} from "./actionTypes"

export const registerUser = (user, history, toast) => {
  return {
    type: REGISTER_USER,
    payload: { user, history, toast },
  }
}

export const registerUserSuccessful = message => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: { message },
  }
}

export const registerUserFailed = message => {
  return {
    type: REGISTER_USER_FAILED,
    payload: { message },
  }
}
