const {
  GET_PRODUCT_DATA,
  GET_PRODUCT_DATA_SUCCESSFUL,
  GET_PRODUCT_DATA_FAIL,
  STORE_PRODUCT_DATA,
} = require("./actionType")

const initialState = {
  data: {},
  loading: false,
  error: "",
}

const productData = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_DATA:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_PRODUCT_DATA_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        data: action.payload,
      }
      break

    case STORE_PRODUCT_DATA:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      }
    case GET_PRODUCT_DATA_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      }
      break

    default:
      state = {
        ...state,
      }
      break
  }
  return state
}

export default productData
