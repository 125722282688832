import {
  ADD_NEW_PRODUCT,
  ADD_PRODUCT_FAIL,
  ADD_PRODUCT_SUCCESS,
  GET_PRODUCT,
  GET_PRODUCTS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCT_FAIL,
  GET_PRODUCT_SUCCESS,
  GET_TOTAL_PRODUCT_QUANTITY,
  GET_TOTAL_PRODUCT_QUANTITY_FAIL,
  GET_TOTAL_PRODUCT_QUANTITY_SUCCESS,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_SUCCESS,
} from "./actionTypes"

export const addNewProduct = (data, history, authtoken) => ({
  type: ADD_NEW_PRODUCT,
  payload: { data, history, authtoken },
})

export const addProductSuccess = data => ({
  type: ADD_PRODUCT_SUCCESS,
  payload: data,
})

export const addProductFail = error => ({
  type: ADD_PRODUCT_FAIL,
  payload: error,
})
export const getUsers = authtoken => ({
  type: GET_USERS,
  payload: { authtoken },
})

export const getUsersSuccess = data => ({
  type: GET_USERS_SUCCESS,
  payload: data,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})
export const getProducts = (authtoken, orgid) => ({
  type: GET_PRODUCTS,
  payload: { authtoken, orgid },
})

export const getProductsSuccess = (data, authtoken) => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: { data, authtoken },
})

export const getProductsFail = error => ({
  type: GET_PRODUCTS_FAIL,
  payload: error,
})
export const getProduct = (authtoken, id) => ({
  type: GET_PRODUCT,
  payload: { authtoken, id },
})

export const getProductSuccess = (data, authtoken) => ({
  type: GET_PRODUCT_SUCCESS,
  payload: { data, authtoken },
})

export const getProductFail = error => ({
  type: GET_PRODUCT_FAIL,
  payload: error,
})
export const updateProduct = (formdata, authtoken, productID, history) => ({
  type: UPDATE_PRODUCT,
  payload: { formdata, authtoken, productID, history },
})

export const updateProductSuccess = (authtoken, productID, data) => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload: { authtoken, productID, data },
})

export const updateProductFail = error => ({
  type: UPDATE_PRODUCT_FAIL,
  payload: error,
})

export const getTotalProductAmount = authtoken => ({
  type: GET_TOTAL_PRODUCT_QUANTITY,
  payload: { authtoken },
})

export const getTotalProductAmountSuccess = (data, authtoken) => ({
  type: GET_TOTAL_PRODUCT_QUANTITY_SUCCESS,
  payload: { data, authtoken },
})

export const getTotalProductAmountFail = error => ({
  type: GET_TOTAL_PRODUCT_QUANTITY_FAIL,
  payload: error,
})
