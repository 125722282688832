import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { postJwtProfile } from "../../../helpers/backend_helper"
import { profileError, profileSuccess } from "./actions"
// Login Redux States
import { EDIT_PROFILE } from "./actionTypes"

function* editProfile({ payload: { user } }) {
  try {
    const response = yield call(postJwtProfile, "/post-jwt-profile", {
      username: user.username,
      idx: user.idx,
    })
    yield put(profileSuccess(response))
  } catch (error) {
    yield put(profileError(error))
  }
}
export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
