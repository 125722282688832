import {
  ADD_NEW_TARGET, ADD_TARGET_FAIL, ADD_TARGET_SUCCESS,
  GET_ALL_TARGET, GET_ALL_TARGET_FAIL, GET_ALL_TARGET_SUCCESS,
  UPDATE_TARGET,UPDATE_TARGET_SUCCESS,UPDATE_TARGET_FAIL
} from "./actionTypes"

  export const addNewTarget = (data, history,authtoken) => ({
    type: ADD_NEW_TARGET,
    payload: { data, history ,authtoken},
  })
  
  export const addTargetSuccess = data => ({
    type: ADD_TARGET_SUCCESS,
    payload: data,
  })
  
  export const addTargetFail = error => ({
    type: ADD_TARGET_FAIL,
    payload: error,
  })
  export const getAllTarget = (authtoken) => ({
    type: GET_ALL_TARGET,
    payload: { authtoken }
  })
  
  export const getAllTargetSuccess = data => ({
    type: GET_ALL_TARGET_SUCCESS,
    payload: data,
  })
  
  export const getAllTargetFail = error => ({
    type: GET_ALL_TARGET_FAIL,
    payload: error,
  })

  export const updateTarget = (formdata, authtoken, productID, history) => ({
    type: UPDATE_TARGET,
    payload: { formdata, authtoken, productID, history },
  })
  
  export const updateTargetSuccess = (authtoken, productID, data) => ({
    type: UPDATE_TARGET_SUCCESS,
    payload: { authtoken, productID, data },
  })
  
  export const updateTargetFail = error => ({
    type: UPDATE_TARGET_FAIL,
    payload: error,
  })