import { toast } from "react-toastify"
import { call, put, takeEvery } from "redux-saga/effects"
import { addUnitData, getUnitData } from "../../helpers/backend_helper.js"
import { ADD_NEW_UNIT, GET_UNITS } from "./actionTypes"
import {
  addUnitFail,
  addUnitSuccess,
  getUnitsFail,
  getUnitsSuccess,
} from "./actions"

function* onAddUnit({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(addUnitData, data, authtoken)
    //  console.log('hi');
    //  console.log(response);
    yield put(addUnitSuccess(response))
    toast("🦄 Unit added successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    history.push("/unit-list")
  } catch (error) {
    if (!error.response) {
      toast("🦄 Unit added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      history.push("/unit-list")
    } else {
      let message = error.response.data.message
      yield put(addUnitFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}

function* fetchUnit({ payload: { authtoken } }) {
  try {
    //  console.log('fetched area');
    const response = yield call(getUnitData, authtoken)
    //  console.log(response);
    yield put(getUnitsSuccess(response))
  } catch (error) {
    yield put(getUnitsFail(error))
  }
}

function* UnitSaga() {
  yield takeEvery(ADD_NEW_UNIT, onAddUnit)
  yield takeEvery(GET_UNITS, fetchUnit)
}

export default UnitSaga
