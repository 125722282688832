import{
    ADD_NEW_INVENTORY_SUCCESS,
    ADD_NEW_INVENTORY_FAIL,
    GET_NEW_INVENTORY_SUCCESS,
    GET_NEW_INVENTORY_FAIL,
    GET_DAMAGE_INVENTORY_SUCCESS,
    GET_DAMAGE_INVENTORY_FAIL,
    GET_DAMAGE_ACTIVITY_SUCCESS,
    GET_DAMAGE_ACTIVITY_FAIL,
    GET_DAMAGE_RECONCILIATION_SUCCESS,
    GET_DAMAGE_RECONCILIATION_FAIL,
    ADD_DAMAGE_INVENTORY_SUCCESS,
    ADD_DAMAGE_INVENTORY_FAIL,
    STORE_PRODUCT_DATA,
    STORE_RECONCILED_DATA
   
} from './actionTypes'
const INIT_STATE = {
    inventories: [],
    damageInventories: [],
    damageActivity: [],
    damageReconciliation: [],
    error: {},
    inventoryLoading:true,
    damageInventoryLoading:true,
    DamageLoading: true,
    damageActivityLoading: true,
    damageReconciliationLoading: true
  }

  const InventoryReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
     
      case ADD_NEW_INVENTORY_SUCCESS:
        return {
          ...state,
          inventories: [...state.inventories, action.payload],
          inventoryLoading: false,
  
        }
      case ADD_NEW_INVENTORY_FAIL:
        return {
          ...state,
          error: action.payload,
          inventoryLoading: true,
        }
        case ADD_DAMAGE_INVENTORY_SUCCESS:
          return {
            ...state,
            damageInventories: [...state.damageInventories, action.payload],
            damageInventoryLoading: false,
    
          }
        case ADD_DAMAGE_INVENTORY_FAIL:
          return {
            ...state,
            error: action.payload,
            damageInventoryLoading: true,
          }
        case GET_NEW_INVENTORY_SUCCESS:
          return {
            ...state,
            inventories: action.payload,
            inventoryLoading: false,
          
          }
        case GET_NEW_INVENTORY_FAIL:
          return {
            ...state,
            error: action.payload,
            inventoryLoading: true,
           
    
          }
          case GET_DAMAGE_INVENTORY_SUCCESS:
            return {
              ...state,
              damageInventories: action.payload,
              damageInventoryLoading: false,
            
            }
          case GET_DAMAGE_INVENTORY_FAIL:
            return {
              ...state,
              error: action.payload,
              damageInventoryLoading: true,
             
      
            }
          case GET_DAMAGE_ACTIVITY_SUCCESS:
            return {
              ...state,
              damageActivity: action.payload,
              damageActivityLoading: false,
            
            }
          case GET_DAMAGE_ACTIVITY_FAIL:
            return {
              ...state,
              error: action.payload,
              damageActivityLoading: true,
             
      
            }
            case GET_DAMAGE_RECONCILIATION_SUCCESS:
              return {
                ...state,
                damageReconciliation: action.payload,
                damageReconciliationLoading: false,
               
        
              }
         
          case GET_DAMAGE_RECONCILIATION_FAIL:
            return {
              ...state,
              error: action.payload,
              damageReconciliationLoading: true,
             
      
            }
            case STORE_PRODUCT_DATA:
              return {
                ...state,
                [action.payload.name]: action.payload.data,
              }
              case STORE_RECONCILED_DATA:
                return {
                  ...state,
                  [action.payload.name]: action.payload.data,
                }
     
      default:
        return state
    }
  }
  
  export default InventoryReducer