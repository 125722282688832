import {
    GET_TO_ORDER,
    GET_TO_ORDER_SUCCESS,
    GET_TO_ORDER_FAIL,
    GET_TO_SALE,
    GET_TO_SALE_SUCCESS,
    GET_TO_SALE_FAIL,
    GET_SO_BY_TO,
    GET_SO_BY_TO_SUCCESS,
    GET_SO_BY_TO_FAIL,
    GET_TO_DISTRIBUTION,
    GET_TO_DISTRIBUTION_SUCCESS,
    GET_TO_DISTRIBUTION_FAIL,
    GET_TO_PRODUCT,
    GET_TO_PRODUCT_SUCCESS,
    GET_TO_PRODUCT_FAIL,
    ADD_STOCK_TO,
    ADD_STOCK_TO_SUCCESS,
    ADD_STOCK_TO_FAIL,
    GET_INVENTORY,
    GET_INVENTORY_SUCCESS,
    GET_INVENTORY_FAIL,
    GET_REQUISITION,
    GET_REQUISITION_SUCCESS,
    GET_REQUISITION_FAIL,
    GET_STOCK_REPORT,
    GET_STOCK_REPORT_SUCCESS,
    GET_STOCK_REPORT_FAIL,
    GET_ORDER_REPORT,
    GET_ORDER_REPORT_SUCCESS,
    GET_ORDER_REPORT_FAIL,
    GET_SALES_REPORT,
    GET_SALES_REPORT_SUCCESS,
    GET_SALES_REPORT_FAIL,
    GET_SINGLE_ORDER,
    GET_SINGLE_ORDER_SUCCESS,
    GET_SINGLE_ORDER_FAIL,
    GET_TO_DELIVERABLES,
    GET_TO_DELIVERABLES_SUCCESS,
    GET_TO_DELIVERABLES_FAIL,
    GET_DAY_OC_REPORT,
    GET_DAY_OC_REPORT_SUCCESS,
    GET_DAY_OC_REPORT_FAIL,
    DO_DAY_CLOSE,
    DO_DAY_CLOSE_SUCCESS,
    DO_DAY_CLOSE_FAIL,
    STOCK_REPORT_DETAILS,
    STOCK_REPORT_DETAILS_SUCCESS,
    STOCK_REPORT_DETAILS_FAIL,
    UPDATE_STOCK,
    UPDATE_STOCK_SUCCESS,
    UPDATE_STOCK_FAIL,
    STORE_TO_DATA,
    GET_SO_BY_RBH,
    GET_SO_BY_RBH_SUCCESS,
    GET_SO_BY_RBH_FAIL
  } from "./actionTypes"


  export const getOrderTO = (authtoken, startDate, endDate, limit, currentPage, SOList, area, territory, distribution) => ({
    type: GET_TO_ORDER,
    payload: { authtoken, startDate, endDate, limit, currentPage, SOList, area, territory, distribution },
  })
  
  export const getOrderTOSuccess = (data, authtoken) => ({
    type: GET_TO_ORDER_SUCCESS,
    payload: { data, authtoken },
  })
  
  export const getOrderTOFail = error => ({
    type: GET_TO_ORDER_FAIL,
    payload: error,
  })

  export const getSaleTO = (authtoken, startDate, endDate, limit, currentPage, SOList, area, territory, distribution) => ({
    type: GET_TO_SALE,
    payload: { authtoken, startDate, endDate, limit, currentPage, SOList, area, territory, distribution },
  })
  
  export const getSaleTOSuccess = (data, authtoken) => ({
    type: GET_TO_SALE_SUCCESS,
    payload: { data, authtoken },
  })
  
  export const getSaleTOFail = error => ({
    type: GET_TO_SALE_FAIL,
    payload: error,
  })
  export const getSOByTO = (authtoken, toID) => ({
    type: GET_SO_BY_TO,
    payload: { authtoken, toID },
  })
  
  export const getSOByTOSuccess = (data, authtoken) => ({
    type: GET_SO_BY_TO_SUCCESS,
    payload: { data, authtoken },
  })
  
  export const getSOByTOFail = error => ({
    type: GET_SO_BY_TO_FAIL,
    payload: error,
  })

  export const getSOByRBH = (authtoken, toID) => ({
    type: GET_SO_BY_RBH,
    payload: { authtoken, toID },
  })
  
  export const getSOByRBHSuccess = (data, authtoken) => ({
    type: GET_SO_BY_RBH_SUCCESS,
    payload: { data, authtoken },
  })
  
  export const getSOByRBHFail = error => ({
    type: GET_SO_BY_RBH_FAIL,
    payload: error,
  })

  export const getToDistribution = (authtoken, area) => ({
    type: GET_TO_DISTRIBUTION,
    payload: { authtoken, area },
  })
  
  export const getToDistributionSuccess = (data, authtoken) => ({
    type: GET_TO_DISTRIBUTION_SUCCESS,
    payload: { data, authtoken },
  })
  
  export const getToDistributionFail = error => ({
    type: GET_TO_DISTRIBUTION_FAIL,
    payload: error,
  })

  export const getToProduct = (authtoken, id) => ({
    type: GET_TO_PRODUCT,
    payload: { authtoken, id },
  })
  
  export const getToProductSuccess = (data, authtoken) => ({
    type: GET_TO_PRODUCT_SUCCESS,
    payload: { data, authtoken },
  })
  
  export const getToProductFail = error => ({
    type: GET_TO_PRODUCT_FAIL,
    payload: error,
  })

  export const addToStock = (data, history, authtoken) => ({
    type: ADD_STOCK_TO,
    payload: { data, history, authtoken },
  })
  
  export const addToStockSuccess = data => ({
    type: ADD_STOCK_TO_SUCCESS,
    payload: data,
  })
  
  export const addToStockFail = error => ({
    type: ADD_STOCK_TO_FAIL,
    payload: error,
  })

  export const getInventory = (authtoken, disbritutionID) => ({
    type: GET_INVENTORY,
    payload: { authtoken, disbritutionID },
  })
  
  export const getInventorySuccess = (data, authtoken) => ({
    type: GET_INVENTORY_SUCCESS,
    payload: { data, authtoken },
  })
  
  export const getInventoryFail = error => ({
    type: GET_INVENTORY_FAIL,
    payload: error,
  })

  export const getRequisition = (authtoken, limit, currentPage, disbritutionID, status, requestNo) => ({
    type: GET_REQUISITION,
    payload: { authtoken, limit, currentPage, disbritutionID, status, requestNo },
  })
  
  export const getRequisitionSuccess = (data, authtoken) => ({
    type: GET_REQUISITION_SUCCESS,
    payload: { data, authtoken },
  })
  
  export const getRequisitionFail = error => ({
    type: GET_REQUISITION_FAIL,
    payload: error,
  })
  export const getStockReport = (authtoken, limit, currentPage, disbritutionID, fromDate, toDate,  product) => ({
    type: GET_STOCK_REPORT,
    payload: { authtoken, limit, currentPage, disbritutionID, fromDate, toDate,  product },
  })
  
  export const getStockReportSuccess = (data, authtoken) => ({
    type: GET_STOCK_REPORT_SUCCESS,
    payload: { data, authtoken },
  })
  
  export const getStockReportFail = error => ({
    type: GET_STOCK_REPORT_FAIL,
    payload: error,
  })

  export const getOrderReport = (authtoken, area) => ({
    type: GET_ORDER_REPORT,
    payload: { authtoken, area },
  })
  
  export const getOrderReportSuccess = (data, authtoken) => ({
    type: GET_ORDER_REPORT_SUCCESS,
    payload: { data, authtoken },
  })
  
  export const getOrderReportFail = error => ({
    type: GET_ORDER_REPORT_FAIL,
    payload: error,
  })
  export const getSalesReport = (authtoken, area) => ({
    type: GET_SALES_REPORT,
    payload: { authtoken, area },
  })
  
  export const getSalesReportSuccess = (data, authtoken) => ({
    type: GET_SALES_REPORT_SUCCESS,
    payload: { data, authtoken },
  })
  
  export const getSalesReportFail = error => ({
    type: GET_SALES_REPORT_FAIL,
    payload: error,
  })

  export const getSingleOrder = (authtoken, orderId) => ({
    type: GET_SINGLE_ORDER,
    payload: { authtoken, orderId },
  })
  
  export const getSingleOrderSuccess = (data, authtoken) => ({
    type: GET_SINGLE_ORDER_SUCCESS,
    payload: { data, authtoken },
  })
  
  export const getSingleOrderFail = error => ({
    type: GET_SINGLE_ORDER_FAIL,
    payload: error,
  })


  export const getToDeliverables = (authtoken, office) => ({
    type: GET_TO_DELIVERABLES,
    payload: { authtoken, office },
  })
  
  export const getToDeliverablesSuccess = (data, authtoken) => ({
    type: GET_TO_DELIVERABLES_SUCCESS,
    payload: { data, authtoken },
  })
  
  export const getToDeliverablesFail = error => ({
    type: GET_TO_DELIVERABLES_FAIL,
    payload: error,
  })
  export const getOcReport = (authtoken, office, date, limit, pageNo) => ({
    type: GET_DAY_OC_REPORT,
    payload: { authtoken, office, date, limit, pageNo },
  })
  
  export const getOcReportSuccess = (data, authtoken) => ({
    type: GET_DAY_OC_REPORT_SUCCESS,
    payload: { data, authtoken },
  })
  
  export const getOcReportFail = error => ({
    type: GET_DAY_OC_REPORT_FAIL,
    payload: error,
  })

  
  export const doDayClose = (authtoken, office, area) => ({
    type: DO_DAY_CLOSE,
    payload: { authtoken, office, area },
  })
  
  export const doDayCloseSuccess = (data, authtoken) => ({
    type: DO_DAY_CLOSE_SUCCESS,
    payload: { data, authtoken },
  })
  
  export const doDayCloseFail = error => ({
    type: DO_DAY_CLOSE_FAIL,
    payload: error,
  })


  export const stockReportDetails = (data, history, authtoken) => ({
    type: STOCK_REPORT_DETAILS,
    payload: { data, history, authtoken },
  })
  
  export const stockReportDetailsSuccess = data => ({
    type: STOCK_REPORT_DETAILS_SUCCESS,
    payload: data,
  })
  
  export const stockReportDetailsFail = error => ({
    type: STOCK_REPORT_DETAILS_FAIL,
    payload: error,
  })

  export const updateStock = (data, history, authtoken, distributionId) => ({
    type: UPDATE_STOCK,
    payload: { data, history, authtoken, distributionId },
  })
  
  export const updateStockSuccess = data => ({
    type: UPDATE_STOCK_SUCCESS,
    payload: data,
  })
  
  export const updateStockFail = error => ({
    type: UPDATE_STOCK_FAIL,
    payload: error,
  })

  export const storeToData = (name, data) => ({
    type: STORE_TO_DATA,
    payload: { name, data },
  })