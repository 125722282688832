import {
    ADD_UNIT_FAIL,
    ADD_UNIT_SUCCESS,
    ADD_NEW_UNIT,
    GET_UNITS,
    GET_UNITS_FAIL ,
    GET_UNITS_SUCCESS
   

  } from "./actionTypes"
    
    export const addNewUnit = (data, history,authtoken) => ({
      type: ADD_NEW_UNIT,
      payload: { data, history ,authtoken},
    })
    
    export const addUnitSuccess = data => ({
      type: ADD_UNIT_SUCCESS,
      payload: data,
    })
    
    export const addUnitFail = error => ({
      type: ADD_UNIT_FAIL,
      payload: error,
    })
  
  export const getUnits = (authtoken) => ({
    type: GET_UNITS,
    payload: { authtoken }
  })
  
  export const getUnitsSuccess = data => ({
    type:GET_UNITS_SUCCESS,
    payload: data,
  })
  
  export const getUnitsFail = error => ({
    type: GET_UNITS_FAIL ,
    payload: error,
  })
  