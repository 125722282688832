import{
    ADD_NEW_INVENTORY,
    ADD_NEW_INVENTORY_SUCCESS,
    ADD_NEW_INVENTORY_FAIL,
    GET_NEW_INVENTORY,
    GET_NEW_INVENTORY_SUCCESS,
    GET_NEW_INVENTORY_FAIL,
    GET_DAMAGE_ACTIVITY,
    GET_DAMAGE_ACTIVITY_SUCCESS,
    GET_DAMAGE_ACTIVITY_FAIL,
    ADD_DAMAGE_ACTIVITY,
    ADD_DAMAGE_ACTIVITY_SUCCESS,
    ADD_DAMAGE_ACTIVITY_FAIL,
    GET_DAMAGE_RECONCILIATION,
    GET_DAMAGE_RECONCILIATION_SUCCESS,
    GET_DAMAGE_RECONCILIATION_FAIL,
    ADD_DAMAGE_RECONCILIATION,
    ADD_DAMAGE_RECONCILIATION_SUCCESS,
    ADD_DAMAGE_RECONCILIATION_FAIL,
    GET_DAMAGE_INVENTORY,
    GET_DAMAGE_INVENTORY_SUCCESS,
    GET_DAMAGE_INVENTORY_FAIL,
    ADD_DAMAGE_INVENTORY,
    ADD_DAMAGE_INVENTORY_SUCCESS,
    ADD_DAMAGE_INVENTORY_FAIL,
    STORE_PRODUCT_DATA,
    STORE_RECONCILED_DATA
   
} from './actionTypes'
export const addNewInventory = (data,history,authtoken,officeId) =>({
    type: ADD_NEW_INVENTORY,
    payload: { data,history,authtoken,officeId},

})

export const addNewInventorySuccess = data => ({
    type: ADD_NEW_INVENTORY_SUCCESS,
    payload: data,
  })
  
  export const addNewInventoryFail = error => ({
    type: ADD_NEW_INVENTORY_FAIL,
    payload: error,
  })
 
  export const getNewInventory = (authtoken, officeId) => ({
    type: GET_NEW_INVENTORY,
    payload: { authtoken, officeId },
  })
  
 
  
  export const getNewInventorySuccess = (data, authtoken) => ({
    type: GET_NEW_INVENTORY_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getNewInventoryFail = error => ({
    type: GET_NEW_INVENTORY_FAIL,
    payload: error,
  })

  export const addDamageInventory = (data,history,authtoken,officeId) =>({
    type: ADD_DAMAGE_INVENTORY,
    payload: { data,history,authtoken,officeId},

})

export const addDamageInventorySuccess = data => ({
    type: ADD_DAMAGE_INVENTORY_SUCCESS,
    payload: data,
  })
  
  export const addDamageInventoryFail = error => ({
    type: ADD_DAMAGE_INVENTORY_FAIL,
    payload: error,
  })
  export const getDamageInventory = (authtoken, officeId) => ({
    type: GET_DAMAGE_INVENTORY,
    payload: { authtoken, officeId },
  })
  
 
  
  export const getDamageInventorySuccess = (data, authtoken) => ({
    type: GET_DAMAGE_INVENTORY_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getDamageInventoryFail = error => ({
    type: GET_DAMAGE_INVENTORY_FAIL,
    payload: error,
  })
  export const getDamageActivity= (authtoken) => ({
    type: GET_DAMAGE_ACTIVITY,
    payload: { authtoken },
  })
  
 
  
  export const getDamageActivitySuccess = (data, authtoken) => ({
    type: GET_DAMAGE_ACTIVITY_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getDamageActivityFail = error => ({
    type: GET_DAMAGE_ACTIVITY_FAIL,
    payload: error,
  })
  export const addDamageActivity = (data, history, authtoken) =>({
    type: ADD_DAMAGE_ACTIVITY,
    payload: {data, history, authtoken},

})

export const addDamageActivitySuccess = data => ({
    type: ADD_DAMAGE_ACTIVITY_SUCCESS,
    payload: data,
  })
  
  export const addDamageActivityFail = error => ({
    type: ADD_DAMAGE_ACTIVITY_FAIL,
    payload: error,
  })
  export const getDamageReconciliation= (authtoken, currentDate) => ({
    type: GET_DAMAGE_RECONCILIATION,
    payload: { authtoken, currentDate },
  })
  
 
  
  export const getDamageReconciliationSuccess = (data, authtoken) => ({
    type: GET_DAMAGE_RECONCILIATION_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getDamageReconciliationFail = error => ({
    type: GET_DAMAGE_RECONCILIATION_FAIL,
    payload: error,
  })

  export const addDamageReconciliation = (data, history, authtoken, currentDate) =>({
    type: ADD_DAMAGE_RECONCILIATION,
    payload: {data, history, authtoken, currentDate },

})

export const addDamageReconciliationSuccess = data => ({
    type: ADD_DAMAGE_RECONCILIATION_SUCCESS,
    payload: data,
  })
  
  export const addDamageReconciliationFail = error => ({
    type: ADD_DAMAGE_RECONCILIATION_FAIL,
    payload: error,
  })
  export const storeProductData = (name, data) => ({
    type: STORE_PRODUCT_DATA,
    payload: { name, data },
  })
  export const storeReconciledData = (name, data) => ({
    type: STORE_RECONCILED_DATA,
    payload: { name, data },
  })
  