import { call, put, takeEvery } from "redux-saga/effects"
import {
  getAvgInvoiceValueData,
  getBounceRateData,
  getDashBoardChartData,
  getDashBoardOverviewData,
  getDashBoardSellingOverview,
  getDashboardAnalysisOne,
  getDashboardOrderSalesApi,
  getFilterData,
  getPreOrderDelivery,
  getTargetAnalysis,
  getTopBrandData,
  getTopDPData,
  getTopProdutcsData,
  getTopRegionData,
  getTopSRData,
  getTotalDPData,
  getTotalSRData,
} from "../../helpers/backend_helper"
import {
  GET_AREA_BY_REGION,
  GET_AVG_INVOICE_VALUE,
  GET_BOUNCE_DATA,
  GET_DASHBOARD_CHART,
  GET_DASHBOARD_ORDER_SALES,
  GET_DASHBOARD_OVERVIEW,
  GET_MAP_DATA,
  GET_PRE_ORDER_DELIVERY,
  GET_REVENUE_INFO,
  GET_SELLING_OVERVIEW,
  GET_TARGET_ANALYSIS,
  GET_TERRITORY_BY_AREA,
  GET_TOP_BRAND,
  GET_TOP_DP,
  GET_TOP_PRODUCT,
  GET_TOP_REGION,
  GET_TOP_SR,
  GET_TOTAL_DP,
  GET_TOTAL_SR,
} from "./actionTypes"
import {
  getAvgInvoiceValueFail,
  getAvgInvoiceValueSuccess,
  getDashboardChartFail,
  getDashboardChartSuccess,
  getDashboardOrderSales,
  getDashboardOrderSalesFail,
  getDashboardOrderSalesSuccess,
  getDashboardOverviewFail,
  getDashboardOverviewSuccess,
  getMapDataFail,
  getMapDataSuccess,
  getOrderBounceFail,
  getOrderBounceSuccess,
  getPreDeliveryFail,
  getPreDeliverySuccess,
  getRevenueInfoFail,
  getRevenueInfoSuccess,
  getSellingOverviewFail,
  getSellingOverviewSuccess,
  getTargetAnalysisFail,
  getTargetAnalysisSuccess,
  getTopBrandFail,
  getTopBrandSuccess,
  getTopDPFail,
  getTopDPSuccess,
  getTopProductFail,
  getTopProductSuccess,
  getTopRegionFail,
  getTopRegionSuccess,
  getTopSRFail,
  getTopSRSuccess,
  getTotalDpFail,
  getTotalDpSuccess,
  getTotalSRFail,
  getTotalSRSuccess,
  storeDashboardReducerData,
} from "./actions"

function* fetchDashBoardOverview({ payload: { authtoken } }) {
  try {
    const response = yield call(getDashBoardOverviewData, authtoken)
    //  console.log(response)
    yield put(getDashboardOverviewSuccess(response))
  } catch (error) {
    //  console.log("hello", error)
    yield put(getDashboardOverviewFail(error))
    //  console.log("hi")
  }
}
function* fetchDashBoardChart({ payload: { authtoken, query } }) {
  try {
    // console.log(authtoken)
    //  console.log("fetchDashBoardChart",query);
    const response = yield call(getDashBoardChartData, authtoken, query)
    // console.log("hello", response)
    yield put(getDashboardChartSuccess(response))
  } catch (error) {
    yield put(getDashboardChartFail(error))

    // console.log("hi")
  }
}
function* fetchSellingOverview({ payload: { authtoken, query } }) {
  try {
    const response = yield call(getDashBoardSellingOverview, authtoken, query)
    //  console.log(response)
    yield put(getSellingOverviewSuccess(response))
  } catch (error) {
    yield put(getSellingOverviewFail(error))

    //  console.log("selling")
  }
}
function* fetchTopProducts({ payload: { authtoken, query } }) {
  try {
    //  console.log(authtoken)
    const response = yield call(getTopProdutcsData, authtoken, query)
    //  console.log(response)
    yield put(getTopProductSuccess(response))
  } catch (error) {
    yield put(getTopProductFail(error))
    //  console.log("hi")
  }
}

function* fetchTotalSR({ payload: { authtoken, query } }) {
  try {
    const response = yield call(getTotalSRData, authtoken, query)
    //  console.log(response)
    yield put(getTotalSRSuccess(response))
  } catch (error) {
    yield put(getTotalSRFail(error))
    //  console.log("total sr fail")
  }
}
function* fetchTotalDP({ payload: { authtoken, id } }) {
  try {
    const response = yield call(getTotalDPData, authtoken, id)
    //  console.log(response)
    yield put(getTotalDpSuccess(response))
  } catch (error) {
    yield put(getTotalDpFail(error))
    //  console.log("hi")
  }
}
function* fetchTopSR({ payload: { authtoken, query } }) {
  //  console.log("topsr",authtoken);
  try {
    const response = yield call(getTopSRData, authtoken, query)
    //  console.log(response)
    yield put(getTopSRSuccess(response))
  } catch (error) {
    yield put(getTopSRFail(error))
    //  console.log("hi")
  }
}
function* fetchTopDP({ payload: { authtoken, id } }) {
  try {
    const response = yield call(getTopDPData, authtoken, id)
    //  console.log(response)
    yield put(getTopDPSuccess(response))
  } catch (error) {
    yield put(getTopDPFail(error))
    //  console.log("hi")
  }
}
function* fetchTopBrand({ payload: { authtoken, query } }) {
  try {
    const response = yield call(getTopBrandData, authtoken, query)
    //  console.log(response)
    yield put(getTopBrandSuccess(response))
  } catch (error) {
    yield put(getTopBrandFail(error))
    //  console.log("hi")
  }
}
function* fetchTopRegion({ payload: { authtoken, query } }) {
  try {
    //  console.log(query)
    const response = yield call(getTopRegionData, authtoken, query)
    //  console.log(response)
    yield put(getTopRegionSuccess(response))
  } catch (error) {
    yield put(getTopRegionFail(error))
    //  console.log("hi")
  }
}
function* fetchPreOrderDelivery({ payload: { authtoken, query } }) {
  try {
    const response = yield call(getPreOrderDelivery, authtoken, query)
    //  console.log(response)
    yield put(getPreDeliverySuccess(response))
  } catch (error) {
    yield put(getPreDeliveryFail(error))
    //  console.log("hi")
  }
}

function* fetchBounceData({ payload: { authtoken, id } }) {
  try {
    const response = yield call(getBounceRateData, authtoken, id)
    //  console.log(response)
    yield put(getOrderBounceSuccess(response))
  } catch (error) {
    yield put(getOrderBounceFail(error))
    //  console.log("hi")
  }
}
function* fetchInvoiceValue({ payload: { authtoken, query } }) {
  try {
    const response = yield call(getAvgInvoiceValueData, authtoken, query)
    //  console.log(response)
    yield put(getAvgInvoiceValueSuccess(response))
  } catch (error) {
    yield put(getAvgInvoiceValueFail(error))
  }
}

function* fetchAreaByRegion({ payload: { id, token } }) {
  try {
    const url = `area/orgareaquery?regionid=${id}`
    const response = yield call(getFilterData, token, url)
    yield put(storeDashboardReducerData("areaList", response?.area || []))
  } catch (error) {
    //  console.log(error)
  }
}

function* fetchTerritoryByArea({ payload: { id, token } }) {
  try {
    const url = `/teritory/orgteritory?areaid=${id}`
    const response = yield call(getFilterData, token, url)
    yield put(
      storeDashboardReducerData("territoryList", response?.territory || [])
    )
  } catch (error) {
    //  console.log(error)
  }
}

function* fetchMapData({ payload: { authtoken, ...rest } }) {
  try {
    const url = `/shop/shop-lat-lon-on-filter`
    const response = yield call(getFilterData, authtoken, url, rest)
    console.log(`\n\n  response:`, response)
    yield put(getMapDataSuccess(response?.getData))
  } catch (error) {
    yield put(getMapDataFail(error))
  }
}

function* fetchTargetAnalysis({ payload: { authtoken, query } }) {
  try {
    const response = yield call(getTargetAnalysis, authtoken, query)
    //  console.log(response)
    yield put(getTargetAnalysisSuccess(response))
  } catch (error) {
    yield put(getTargetAnalysisFail(error))
    //  console.log("hi")
  }
}
function* fetchDashBoardAnalysisOne({ payload: { authtoken, query } }) {
  try {
    // console.log(authtoken)
    const response = yield call(getDashboardAnalysisOne, authtoken, query)
    // console.log("hello", response)
    yield put(getRevenueInfoSuccess(response))
  } catch (error) {
    yield put(getRevenueInfoFail(error))

    // console.log("hi")
  }
}
function* fetchDashBoardOrderSales({ payload: { authtoken, api, query, forData } }) {
  try {
    // console.log(authtoken)
    const response = yield call(getDashboardOrderSalesApi, authtoken, api, query, forData)
    // console.log("hello", response)
    yield put(getDashboardOrderSalesSuccess({data:response, forData}))
  } catch (error) {
    yield put(getDashboardOrderSalesFail(error))

    // console.log("hi")
  }
}

function* DashboardSaga() {
  yield takeEvery(GET_DASHBOARD_CHART, fetchDashBoardChart)
  yield takeEvery(GET_TOP_PRODUCT, fetchTopProducts)
  yield takeEvery(GET_TOP_SR, fetchTopSR)
  yield takeEvery(GET_TOP_DP, fetchTopDP)
  yield takeEvery(GET_TOP_BRAND, fetchTopBrand)
  yield takeEvery(GET_TOP_REGION, fetchTopRegion)
  yield takeEvery(GET_DASHBOARD_OVERVIEW, fetchDashBoardOverview)
  yield takeEvery(GET_SELLING_OVERVIEW, fetchSellingOverview)
  yield takeEvery(GET_TOTAL_SR, fetchTotalSR)
  yield takeEvery(GET_TOTAL_DP, fetchTotalDP)
  yield takeEvery(GET_PRE_ORDER_DELIVERY, fetchPreOrderDelivery)
  yield takeEvery(GET_BOUNCE_DATA, fetchBounceData)
  yield takeEvery(GET_AVG_INVOICE_VALUE, fetchInvoiceValue)
  yield takeEvery(GET_AREA_BY_REGION, fetchAreaByRegion)
  yield takeEvery(GET_TERRITORY_BY_AREA, fetchTerritoryByArea)
  yield takeEvery(GET_TARGET_ANALYSIS, fetchTargetAnalysis)
  yield takeEvery(GET_REVENUE_INFO, fetchDashBoardAnalysisOne)
  yield takeEvery(GET_MAP_DATA, fetchMapData)
  yield takeEvery(GET_DASHBOARD_ORDER_SALES, fetchDashBoardOrderSales)
}
export default DashboardSaga
