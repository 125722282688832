import {
  ADD_EXCLUSIVE_SHOP_FAIL,
  ADD_EXCLUSIVE_SHOP_SUCCESS,
  ADD_NEW_STORE,
  ADD_STORE_FAIL,
  ADD_STORE_SUCCESS,
  DELETE_STORE,
  DELETE_STORE_FAIL,
  DELETE_STORE_SUCCESS,
  GET_ALL_STORE_FAIL,
  GET_ALL_STORE_SUCCESS,
  GET_ROUTE_DETAILS_ORDER,
  GET_ROUTE_DETAILS_ORDER_FAIL,
  GET_ROUTE_DETAILS_ORDER_SUCCESS,
  GET_ROUTE_DETAILS_SALES,
  GET_ROUTE_DETAILS_SALES_FAIL,
  GET_ROUTE_DETAILS_SALES_SUCCESS,
  GET_SEARCH_STORE_FAIL,
  GET_SEARCH_STORE_SUCCESS,
  GET_SHOP_DETAILS_MAP,
  GET_SHOP_DETAILS_MAP_FAIL,
  GET_SHOP_DETAILS_MAP_SUCCESS,
  GET_SHOP_GRAPH,
  GET_SHOP_GRAPH_FAIL,
  GET_SHOP_GRAPH_SUCCESS,
  GET_SHOP_MAP,
  GET_SHOP_MAP_SUCCESS,
  GET_SINGLE_ROUTE_DETAILS_ORDER,
  GET_SINGLE_ROUTE_DETAILS_ORDER_FAIL,
  GET_SINGLE_ROUTE_DETAILS_ORDER_SUCCESS,
  GET_SINGLE_ROUTE_DETAILS_SALES,
  GET_SINGLE_ROUTE_DETAILS_SALES_FAIL,
  GET_SINGLE_ROUTE_DETAILS_SALES_SUCCESS,
  GET_STORES_FAIL,
  GET_STORES_SUCCESS,
  GET_STORE_ADD_EXCEL_FAIL,
  GET_STORE_ADD_EXCEL_SUCCESS,
  GET_STORE_ANALYTIC_FAIL,
  GET_STORE_ANALYTIC_SUCCESS,
  GET_STORE_FAIL,
  GET_STORE_ORDER_FAIL,
  GET_STORE_ORDER_SUCCESS,
  GET_STORE_SUCCESS,
  GET_STORE_TOP_PRODUCT_FAIL,
  GET_STORE_TOP_PRODUCT_SUCCESS,
  GET_VISITED_SHOP_FAIL,
  GET_VISITED_SHOP_SUCCESS,
  SEARCH_FILTER,
  UPDATE_STORE,
  UPDATE_STORE_FAIL,
  UPDATE_STORE_SUCCESS,
  VISITED_SHOP_ORDER_NOT_FAIL,
  VISITED_SHOP_ORDER_NOT_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  store: [],
  stores: [],
  topStoreProducts: [],
  exclusiveShop: [],
  storeAnalytic: [],
  searchStore: [],
  storeAddExcel: [],
  storeOrder: [],
  visitedShop: [],
  visitedShopNotOrder: [],
  shopMap: [],
  singleMapShop: [],
  topStoreProduct: {},
  allStore: [],
  shopGraph: [],
  error: {},
  searchFilter: "",
  loading: true,
  topStoreProductLoading: true,
  singleShopLoading: true,
  exclusiveShopLoading: true,
  storeAnalyticLoading: true,
  allStoreLoading: true,
  searchStoreLoading: true,
  storeAddExcelLoading: true,
  storeOrderLoading: true,
  visitedShopLoading: true,
  visitedShopNotOrderLoading: true,
  shopMapLoading: false,
  singleMapShopLoading: false,
  shopGraphDetails: false,
  routeOrderDetailsOrderLoading: false,
  routeOrderDetailsOrder: {},
  singleRouteOrderDetailsOrder: false,
  singleRouteOrderDetailsOrderLoading: {},
  routeOrderDetailsSalesLoading: false,
  routeOrderDetailsSales: {},
  singleRouteOrderDetailsSales: false,
  singleRouteOrderDetailsSalesLoading: {},
}

const StoreReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STORES_SUCCESS:
      return {
        ...state,
        stores: action.payload,
        loading: false,
      }

    case GET_STORES_FAIL:
      return {
        ...state,
        error: action.payload.message,
        loading: true,
      }
    case GET_SHOP_GRAPH:
      return {
        ...state,

        shopGraphDetails: true,
      }
    case GET_SHOP_GRAPH_SUCCESS:
      return {
        ...state,
        shopGraph: action.payload,
        shopGraphDetails: false,
      }
    case GET_SHOP_GRAPH_FAIL:
      return {
        ...state,
        error: action.payload,
        shopGraphDetails: false,
      }
    case GET_STORE_SUCCESS:
      return {
        ...state,
        store: action.payload,
        singleShopLoading: false,
      }
    case GET_STORE_FAIL:
      return {
        ...state,
        error: action.payload,
        singleShopLoading: false,
      }
    case GET_STORE_ORDER_SUCCESS:
      return {
        ...state,
        storeOrder: action.payload,
        storeOrderLoading: false,
      }
    case GET_STORE_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        storeOrderLoading: true,
      }
    case GET_STORE_ANALYTIC_SUCCESS:
      return {
        ...state,
        storeAnalytic: action.payload,
        storeAnalyticLoading: false,
      }
    case GET_STORE_ANALYTIC_FAIL:
      return {
        ...state,
        error: action.payload,
        storeAnalyticLoading: true,
      }

    case ADD_NEW_STORE:
      return {
        ...state,
        loading: true,
      }
    case ADD_STORE_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        loading: false,
      }

    case ADD_STORE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      }
    case ADD_EXCLUSIVE_SHOP_SUCCESS:
      return {
        ...state,
        exclusiveShop: [...state.exclusiveShop, action.payload],
        exclusiveShopLoading: false,
      }
    case ADD_EXCLUSIVE_SHOP_FAIL:
      return {
        ...state,
        error: action.payload,
        exclusiveShopLoading: true,
      }
    case GET_STORE_TOP_PRODUCT_SUCCESS:
      return {
        ...state,
        topStoreProducts: action.payload,
        topStoreProductLoading: false,
      }
    case GET_STORE_TOP_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
        topStoreProductLoading: true,
      }

    case UPDATE_STORE:
      return {
        ...state,
        loading: false,
      }

    case UPDATE_STORE_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
      }

    case UPDATE_STORE_FAIL:
      return {
        ...state,
        error: action.payload.message,
      }
    case DELETE_STORE:
      return {
        ...state,
        loading: false,
      }

    case DELETE_STORE_SUCCESS:
      return {
        ...state,
        stores: state.stores.filter(
          store => store.id.toString() !== action.payload.values.id.toString()
        ),
        loading: false,
      }

    case DELETE_STORE_FAIL:
      return {
        ...state,
        error: action.payload.values,
        loading: false,
      }

    case GET_ALL_STORE_SUCCESS:
      return {
        ...state,
        allStore: action.payload,
        allStoreLoading: false,
      }

    case GET_ALL_STORE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        allStoreLoading: true,
      }
    case GET_SEARCH_STORE_SUCCESS:
      return {
        ...state,
        searchStore: action.payload,
        searchStoreLoading: false,
      }

    case GET_SEARCH_STORE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        searchStoreLoading: true,
      }
    case GET_STORE_ADD_EXCEL_SUCCESS:
      return {
        ...state,
        storeAddExcel: action.payload,
        storeAddExcelLoading: false,
      }

    case GET_STORE_ADD_EXCEL_FAIL:
      return {
        ...state,
        error: action.payload.message,
        storeAddExcelLoading: true,
      }
    case GET_VISITED_SHOP_SUCCESS:
      return {
        ...state,
        visitedShop: action.payload,
        visitedShopLoading: false,
      }

    case GET_VISITED_SHOP_FAIL:
      return {
        ...state,
        error: action.payload.message,
        visitedShopLoading: true,
      }
    case VISITED_SHOP_ORDER_NOT_SUCCESS:
      return {
        ...state,
        visitedShopNotOrder: action.payload,
        visitedShopNotOrderLoading: false,
      }

    case VISITED_SHOP_ORDER_NOT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        visitedShopNotOrderLoading: false,
      }
    case GET_SHOP_MAP:
      return {
        ...state,

        shopMapLoading: true,
      }
    case GET_SHOP_MAP_SUCCESS:
      return {
        ...state,
        shopMap: action.payload,
        shopMapLoading: false,
      }
    case GET_STORES_FAIL:
      return {
        ...state,
        error: action.payload.message,
        shopMapLoading: true,
      }
    case GET_SHOP_DETAILS_MAP:
      return {
        ...state,

        singleMapShopLoading: true,
      }
    case GET_SHOP_DETAILS_MAP_SUCCESS:
      return {
        ...state,
        singleMapShop: action.payload,
        singleMapShopLoading: false,
      }
    case GET_SHOP_DETAILS_MAP_FAIL:
      return {
        ...state,
        error: action.payload.message,
        singleMapShopLoading: true,
      }

    case SEARCH_FILTER:
      return {
        ...state,
        searchFilter: action.payload,
      }

    case GET_ROUTE_DETAILS_ORDER:
      return {
        ...state,
        routeOrderDetailsOrderLoading: true,
      }
    case GET_ROUTE_DETAILS_ORDER_SUCCESS:
      return {
        ...state,
        routeOrderDetailsOrder: action.payload,
        routeOrderDetailsOrderLoading: false,
      }

    case GET_ROUTE_DETAILS_ORDER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        routeOrderDetailsOrderLoading: false,
      }

    case GET_SINGLE_ROUTE_DETAILS_ORDER:
      return {
        ...state,
        singleRouteOrderDetailsOrderLoading: false,
      }
    case GET_SINGLE_ROUTE_DETAILS_ORDER_SUCCESS:
      return {
        ...state,
        singleRouteOrderDetailsOrder: action.payload,
        singleRouteOrderDetailsOrderLoading: false,
      }

    case GET_SINGLE_ROUTE_DETAILS_ORDER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        singleRouteOrderDetailsOrderLoading: false,
      }

    case GET_ROUTE_DETAILS_SALES:
      return {
        ...state,
        routeOrderDetailsSalesLoading: true,
      }
    case GET_ROUTE_DETAILS_SALES_SUCCESS:
      return {
        ...state,
        routeOrderDetailsSales: action.payload,
        routeOrderDetailsSalesLoading: false,
      }

    case GET_ROUTE_DETAILS_SALES_FAIL:
      return {
        ...state,
        error: action.payload.message,
        routeOrderDetailsSalesLoading: false,
      }

    case GET_SINGLE_ROUTE_DETAILS_SALES:
      return {
        ...state,
        singleRouteOrderDetailsSalesLoading: true,
      }

    case GET_SINGLE_ROUTE_DETAILS_SALES_SUCCESS:
      return {
        ...state,
        singleRouteOrderDetailsSales: action.payload,
        singleRouteOrderDetailsSalesLoading: false,
      }

    case GET_SINGLE_ROUTE_DETAILS_SALES_FAIL:
      return {
        ...state,
        error: action.payload.message,
        singleRouteOrderDetailsSalesLoading: false,
      }

    default:
      return state
  }
}

export default StoreReducer
