import {
  ADD_PRODUCT_FAIL,
  ADD_PRODUCT_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCT_FAIL,
  GET_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_SUCCESS,
  GET_TOTAL_PRODUCT_QUANTITY_SUCCESS,
  GET_TOTAL_PRODUCT_QUANTITY_FAIL
} from './actionTypes'
const INIT_STATE = {
  products: [],
  product: [],
  totalAmountProduct:[],
  error: {},
  loading: true,
  isLoading: false,
  testLoading:true,
  productLoading:false,
  offerLoading:true,
  totalAmountLoading: true

}


const ProductReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // case ADD_NEW_PRODUCT:
    //     return {
    //         ...state,
    //         error: {},
    //         isLoading: true,
    //     }
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [...state.products, action.payload],
        testLoading: false,


      }
    case ADD_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        testLoading: true,

      }
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
        productLoading: true,
        offerLoading:false
      }
    case GET_PRODUCTS_FAIL:
      return {
        ...state,

        error: action.payload,
        productLoading: false,
        offerLoading:true

      }
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.payload,
        loading: false,
      }

    case GET_PRODUCT_FAIL:
      return {
        ...state,
        loading: true,
        error: action.payload,
        //change here

      }
      case GET_TOTAL_PRODUCT_QUANTITY_SUCCESS:
        return {
          ...state,
          totalAmountProduct: action.payload,
          totalAmountLoading: false,
        }
  
      case GET_TOTAL_PRODUCT_QUANTITY_FAIL:
        return {
          ...state,
         
          error: action.payload,
          totalAmountLoading: true,

  
        }
      case UPDATE_PRODUCT_SUCCESS:
        return {
          ...state,
          products: state.products.map(product =>
            product.id.toString() === action.payload.id.toString()
              ? { product, ...action.payload }
              : product
          ),
        }
  
      case UPDATE_PRODUCT_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
    default:
      return state
  }
}

export default ProductReducer