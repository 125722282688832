import {
    ADD_UNIT_FAIL,
    ADD_UNIT_SUCCESS, GET_UNITS_FAIL,
    GET_UNITS_SUCCESS
  } from "./actionTypes"
  
  const INIT_STATE = {
      
      units: [],
      unit:{},
      error: {},
      loading: true,
      UnitLoading:false,
  
    }
  
    const UnitReducer = (state = INIT_STATE, action) => {
      switch (action.type) {
        case ADD_UNIT_SUCCESS:
              return {
                ...state,
                isLoading: false,
                units: [...state.units, action.payload],    
              }
        
        case ADD_UNIT_FAIL:
              return {
                ...state,
                error: action.payload,
                loading: false,
              }
        case GET_UNITS_SUCCESS:
          return {
            ...state,
            units: action.payload,
            loading: false,
            UnitLoading:true,
          }
        case GET_UNITS_FAIL:
          return {
            ...state,
            loading: true,
            error: action.payload,
            UnitLoading:false,
          }
        default:
          return state
      }
    }
    
  export default UnitReducer