import {
 
  GET_NEW_HISTORY_SUCCESS,
  GET_NEW_HISTORY_FAIL,
  GET_TODAY_ACTIVITY_SUCCESS,
  GET_TODAY_ACTIVITY_FAIL,
  GET_DP_ACTIVITY_SUCCESS,
  GET_DP_ACTIVITY_FAIL,
  GET_DP_ORDER_ACTIVITY_SUCCESS,
  GET_DP_ORDER_ACTIVITY_FAIL,
  ADD_DP_DAY_OPENING_SUCCESS,
  ADD_DP_DAY_OPENING_FAIL,
  ADD_DP_RECONCILIATION_SUCCESS,
  ADD_DP_RECONCILIATION_FAIL,
  GET_DP_DAY_OPENING_SUCCESS,
  GET_DP_DAY_OPENING_FAIL,
  GET_DP_RECONCILIATION_SUCCESS,
  GET_DP_RECONCILIATION_FAIL,
  GET_DP_DAY_OPENING_CONFIRM_SUCCESS,
  GET_DP_DAY_OPENING_CONFIRM_FAIL,
  UPDATE_ORDER,
  UPDATE_RECONCILIATION,
  GET_RECONCILIATION_CONFIRM_SUCCESS,
  GET_RECONCILIATION_CONFIRM_FAIL
} from "./actionTypes"
const INIT_STATE = {
  Histories: [],
  TodayActivities:[],
  dpActivities:[],
  dpOrderActivities:[],
  dpDayOpening:[],
  dpReconciliation:[],
  dayOpeningConfrim:[],
  reconciliationConfirm:[],
  error: {},
  historyLoading: true,
  todayActivityLoading:true,
  dpActivityLoading:true,
  dpOrderActivityLoading:true,
  dpDayOpeningLoading:true,
  dpReconciliationLoading:true,
  dayOpeningConfrimLoading: true,
  reconciliationConfirmLoading: true


}

const ActivityReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NEW_HISTORY_SUCCESS:
      return {
        ...state,
        Histories: action.payload,
        historyLoading: false,
      }
    case GET_NEW_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
        historyLoading: true,
      }
      case GET_TODAY_ACTIVITY_SUCCESS:
        return {
          ...state,
          TodayActivities: action.payload,
          todayActivityLoading: false,
        }
      case GET_TODAY_ACTIVITY_FAIL:
        return {
          ...state,
          error: action.payload,
          todayActivityLoading: true,
        }
        case UPDATE_ORDER:
          return {
            ...state,
            TodayActivities: action.payload.data,
           
          }
          case UPDATE_RECONCILIATION:
            return {
              ...state,
              dpReconciliation: action.payload.data,
             
            }

        case GET_DP_ACTIVITY_SUCCESS:
          return {
            ...state,
            dpActivities: action.payload,
            dpActivityLoading: false,
          }
        case GET_DP_ACTIVITY_FAIL:
          return {
            ...state,
            error: action.payload,
            dpActivityLoading: true,
          }
          case GET_DP_ORDER_ACTIVITY_SUCCESS:
            return {
              ...state,
              dpOrderActivities: action.payload,
              dpOrderActivityLoading: false,
            }
          case GET_DP_ORDER_ACTIVITY_FAIL:
            return {
              ...state,
              error: action.payload,
              dpOrderActivityLoading: true,
            }
            case ADD_DP_DAY_OPENING_SUCCESS:
              return {
                ...state,
                dpDayOpening: [...state.dpDayOpening, action.payload],
                dpDayOpeningLoading: false,
        
              }
            case ADD_DP_DAY_OPENING_FAIL:
              return {
                ...state,
                error: action.payload,
                dpDayOpeningLoading: true,
              }
              case ADD_DP_RECONCILIATION_SUCCESS:
                return {
                  ...state,
                  dpReconciliation: [...state.dpReconciliation, action.payload],
                  dpReconciliationLoading: false,
          
                }
              case ADD_DP_RECONCILIATION_FAIL:
                return {
                  ...state,
                  error: action.payload,
                  dpReconciliationLoading: true,
                }
            case GET_DP_DAY_OPENING_SUCCESS:
              return {
                ...state,
                dpDayOpening: action.payload,
                dpDayOpeningLoading: false,
              }
            case GET_DP_DAY_OPENING_FAIL:
              return {
                ...state,
                error: action.payload,
                dpDayOpeningLoading: true,
              }
              case GET_DP_RECONCILIATION_SUCCESS:
                return {
                  ...state,
                  dpReconciliation: action.payload,
                  dpReconciliationLoading: false,
                }
              case GET_DP_RECONCILIATION_FAIL:
                return {
                  ...state,
                  error: action.payload,
                  dpReconciliationLoading: true,
                }
                case GET_DP_DAY_OPENING_CONFIRM_SUCCESS:
                  return {
                    ...state,
                    dayOpeningConfrim: action.payload,
                    dayOpeningConfrimLoading: false,
                  }
                case GET_DP_DAY_OPENING_CONFIRM_FAIL:
                  return {
                    ...state,
                    error: action.payload,
                    dayOpeningConfrimLoading: true,
                  }
                  case GET_RECONCILIATION_CONFIRM_SUCCESS:
                    return {
                      ...state,
                      reconciliationConfirm: action.payload,
                      reconciliationConfirmLoading: false,
                    }
                  case GET_RECONCILIATION_CONFIRM_FAIL:
                    return {
                      ...state,
                      error: action.payload,
                      reconciliationConfirmLoading: true,
                    }
      
  

    default:
      return state
  }
}

export default ActivityReducer
