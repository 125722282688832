import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { PersistGate } from "redux-persist/integration/react"
// import App from "./App"
import * as serviceWorker from "./serviceWorker"

import React, { Suspense } from "react"
import Loading from "./components/Atom/Loading"
import { persistor, store } from "./store"
const App = React.lazy(() => import("./App"))

const app = (
  <React.Fragment>
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<Loading />}>
            <App />
          </Suspense>
        </PersistGate>
      </BrowserRouter>
    </Provider>
  </React.Fragment>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
