export const ADD_NEW_TARGET = "ADD_NEW_TARGET"
export const ADD_TARGET_SUCCESS = "ADD_TARGET_SUCCESS"
export const ADD_TARGET_FAIL = "ADD_TARGET_FAIL"


export const GET_ALL_TARGET = "GET_ALL_TARGET"
export const GET_ALL_TARGET_SUCCESS = "GET_ALL_TARGET_SUCCESS"
export const GET_ALL_TARGET_FAIL = "GET_ALL_TARGET_FAIL"

export const UPDATE_TARGET = "UPDATE_TARGET"
export const UPDATE_TARGET_SUCCESS = "UPDATE_TARGET_SUCCESS"
export const UPDATE_TARGET_FAIL = "UPDATE_TARGET_FAIL"