import{
    GET_NEW_HISTORY,
    GET_NEW_HISTORY_SUCCESS,
    GET_NEW_HISTORY_FAIL,
    GET_TODAY_ACTIVITY,
    GET_TODAY_ACTIVITY_SUCCESS,
    GET_TODAY_ACTIVITY_FAIL,
    GET_DP_ACTIVITY,
    GET_DP_ACTIVITY_SUCCESS,
    GET_DP_ACTIVITY_FAIL,
    GET_DP_ORDER_ACTIVITY,
    GET_DP_ORDER_ACTIVITY_SUCCESS,
    GET_DP_ORDER_ACTIVITY_FAIL,
    UPDATE_ORDER,
    ADD_DP_DAY_OPENING,ADD_DP_DAY_OPENING_SUCCESS,ADD_DP_DAY_OPENING_FAIL,
    ADD_DP_RECONCILIATION,ADD_DP_RECONCILIATION_SUCCESS,ADD_DP_RECONCILIATION_FAIL,
    GET_DP_DAY_OPENING,GET_DP_DAY_OPENING_SUCCESS,GET_DP_DAY_OPENING_FAIL,
    GET_DP_RECONCILIATION,GET_DP_RECONCILIATION_SUCCESS,GET_DP_RECONCILIATION_FAIL,
    GET_DP_DAY_OPENING_CONFIRM,GET_DP_DAY_OPENING_CONFIRM_SUCCESS,GET_DP_DAY_OPENING_CONFIRM_FAIL,
    UPDATE_RECONCILIATION,
    GET_RECONCILIATION_CONFIRM,
    GET_RECONCILIATION_CONFIRM_SUCCESS,
    GET_RECONCILIATION_CONFIRM_FAIL
 
   
} from './actionTypes'

  export const getNewHistory= (authtoken, id) => ({
    type: GET_NEW_HISTORY,
    payload: { authtoken, id },
  })
  
 
  
  export const getNewHistorySuccess = (data, authtoken) => ({
    type: GET_NEW_HISTORY_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getNewHistoryFail = error => ({
    type: GET_NEW_HISTORY_FAIL,
    payload: error,
  
  })
  export const getTodayActivity = (authtoken, id, today) => ({
    type: GET_TODAY_ACTIVITY,
    payload: { authtoken, id, today },
  })
  export const updateTodayActivity = (data) => ({
    type: UPDATE_ORDER,
    payload: { data },
  })
  export const updateReconciliation = (data) => ({
    type: UPDATE_RECONCILIATION,
    payload: { data },
  })
  
  
  
 
  
  export const getTodayActivitySuccess = (data, authtoken) => ({
    type: GET_TODAY_ACTIVITY_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getTodayActivityFail = error => ({
    type: GET_TODAY_ACTIVITY_FAIL,
    payload: error,
  })
  export const getDpActivity = (authtoken, id, today) => ({
    type: GET_DP_ACTIVITY,
    payload: { authtoken, id, today },
  })
  
 
  
  export const getDpActivitySuccess = (data, authtoken) => ({
    type: GET_DP_ACTIVITY_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getDpActivityFail = error => ({
    type: GET_DP_ACTIVITY_FAIL,
    payload: error,
  })
  export const getDpOrderActivity = (authtoken, id, today) => ({
    type: GET_DP_ORDER_ACTIVITY,
    payload: { authtoken, id, today },
  })
  
 
  
  export const getDpOrderActivitySuccess = (data, authtoken) => ({
    type: GET_DP_ORDER_ACTIVITY_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getDpOrderActivityFail = error => ({
    type: GET_DP_ORDER_ACTIVITY_FAIL,
    payload: error,
  })
  export const addDpDayOpening = (data, history, authtoken) =>({
    type: ADD_DP_DAY_OPENING,
    payload: {data, history, authtoken},

})

export const addDpDayOpeningSuccess = data => ({
    type: ADD_DP_DAY_OPENING_SUCCESS,
    payload: data,
  })
  
  export const addDpDayOpeningFail = error => ({
    type: ADD_DP_DAY_OPENING_FAIL,
    payload: error,
  })
  export const addDpReconciliation = (data,history,authtoken,officeId) =>({
    type: ADD_DP_RECONCILIATION,
    payload: { data,history,authtoken,officeId},

})

export const addDpReconciliationSuccess = data => ({
    type: ADD_DP_RECONCILIATION_SUCCESS,
    payload: data,
  })
  
  export const addDpReconciliationFail = error => ({
    type: ADD_DP_RECONCILIATION_FAIL,
    payload: error,
  })


  export const getDpDayOpening = (authtoken, id, today) => ({
    type: GET_DP_DAY_OPENING,
    payload: { authtoken, id, today },
  })
  
 
  
  export const getDpDayOpeningSuccess = (data, authtoken) => ({
    type: GET_DP_DAY_OPENING_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getDpDayOpeningFail = error => ({
    type: GET_DP_DAY_OPENING_FAIL,
    payload: error,
  })

  export const getDpDayReconciliation = (authtoken, id, today) => ({
    type: GET_DP_RECONCILIATION,
    payload: { authtoken, id, today },
  })
  
 
  
  export const getDpDayReconciliationSuccess = (data, authtoken) => ({
    type: GET_DP_RECONCILIATION_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getDpDayReconciliationFail = error => ({
    type: GET_DP_RECONCILIATION_FAIL,
    payload: error,
  })

  export const getDpDayOpeningConfirm = (authtoken, dpId, date) => ({
    type: GET_DP_DAY_OPENING_CONFIRM,
    payload: { authtoken, dpId, date},
  })
  
 
  
  export const getDpDayOpeningConfirmSuccess = (data, authtoken) => ({
    type: GET_DP_DAY_OPENING_CONFIRM_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getDpDayOpeningConfirmFail = error => ({
    type: GET_DP_DAY_OPENING_CONFIRM_FAIL,
    payload: error,
  })
  export const getReconciliationConfirm = (authtoken, dpId, date) => ({
    type: GET_RECONCILIATION_CONFIRM,
    payload: { authtoken, dpId, date},
  })
  
 
  
  export const getReconciliationConfirmSuccess = (data, authtoken) => ({
    type: GET_RECONCILIATION_CONFIRM_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getReconciliationConfirmFail = error => ({
    type: GET_RECONCILIATION_CONFIRM_FAIL,
    payload: error,
  })
