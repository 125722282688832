export const ADD_OFFER = "ADD_OFFER"
export const ADD_OFFER_SUCCESS = "ADD_OFFER_SUCCESS"
export const ADD_OFFER_FAIL = "ADD_OFFER_FAIL"

export const ADD_CONSUMER_OFFER = "ADD_CONSUMER_OFFER"
export const ADD_CONSUMER_OFFER_SUCCESS = "ADD_CONSUMER_OFFER_SUCCESS"
export const ADD_CONSUMER_OFFER_FAIL = "ADD_CONSUMER_OFFER_FAIL"

export const ADD_RETAILER_OFFER = "ADD_RETAILER_OFFER"
export const ADD_RETAILER_OFFER_SUCCESS = "ADD_RETAILER_OFFER_SUCCESS"
export const ADD_RETAILER_OFFER_FAIL = "ADD_RETAILER_OFFER_FAIL"

export const GET_OFFERS = "GET_OFFERS"
export const GET_OFFERS_SUCCESS = "GET_OFFERS_SUCCESS"
export const GET_OFFERS_FAIL = "GET_OFFERS_FAIL"

export const GET_OFFER = "GET_OFFER"
export const GET_OFFER_SUCCESS = "GET_OFFER_SUCCESS"
export const GET_OFFER_FAIL = "GET_OFFER_FAIL"

export const GET_OFFER_DETAILS = "GET_OFFER_DETAILS"
export const GET_OFFER_DETAILS_SUCCESS = "GET_OFFER_DETAILS_SUCCESS"
export const GET_OFFER_DETAILS_FAIL = "GET_OFFER_DETAILS_FAIL"