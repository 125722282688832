import { all, fork } from "redux-saga/effects"
import ActivitySaga from "./Activities/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import AuthSaga from "./auth/login/saga"
import ProfileSaga from "./auth/profile/saga"
import UpdateProfilePassSaga from "./auth/profilepwdupdate/saga"
//public
import AccountSaga from "./auth/register/saga"
import updatePasswordSaga from "./auth/updatePassword/saga"
import verifyEmailSaga from "./auth/verifyEmail/saga"
import BrandSaga from "./Brand/saga"
import categorySaga from "./category/saga"
import dashboardSaasSaga from "./dashboard-saas/saga"
import dashboardSaga from "./dashboard/saga"
import GeoInformationSaga from "./GeoInformation/saga"
import InventorySaga from "./Inventory/saga"
import LayoutSaga from "./layout/saga"
import OfferSaga from "./Offer/saga"
import OfficeSaga from "./office/saga"
import OrderSaga from "./Order/saga"
import DashboardSaga from "./OrgDashboard/saga"
import PJPSaga from "./PJP/saga"
import ProductSaga from "./Product/saga"
import { productDataSaga } from "./ProductTable/saga"
import storeSaga from "./Stores/saga"
import targetSaga from "./Target/saga"
import UnitSaga from "./Unit/saga"
import UserSaga from "./users/saga"
import AttendanceSaga from './Attendance/saga'
import TOSaga from './TO/saga'
import RbhSaga from './RBH/saga'
export default function* rootSaga() {
  yield all([
    fork(AccountSaga),
    fork(AuthSaga),
    fork(updatePasswordSaga),
    fork(UpdateProfilePassSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(verifyEmailSaga),
    fork(UserSaga),
    fork(BrandSaga),
    fork(UnitSaga),
    fork(categorySaga),
    fork(GeoInformationSaga),
    fork(OfficeSaga),
    fork(storeSaga),
    fork(ProductSaga),
    fork(OrderSaga),
    fork(OfferSaga),
    fork(PJPSaga),
    fork(targetSaga),
    fork(DashboardSaga),
    fork(InventorySaga),
    fork(ActivitySaga),
    fork(productDataSaga),
    fork(AttendanceSaga),
    fork(TOSaga),
    fork(RbhSaga)
  ])
}
