import { toast } from "react-toastify"
import { call, put, takeEvery } from "redux-saga/effects"
//Include Both Helper File with needed methods
import {
  getOfficeInventoryData,
  getSingleOfficeInventory,
  getofficedata,
  postofficedata,
  updateOfficeInfo,
  updateofficedata,
} from "../../helpers/backend_helper.js"
import {
  addOfficeFail,
  getAllOfficeInventoryFail,
  getAllOfficeInventorySuccess,
  getOfficesFail,
  getOfficesInventoryFail,
  getOfficesInventorySuccess,
  getOfficesSuccess,
  updateOfficeFail,
  updateOfficeSuccess,
  updateOfficeworkingdayFail,
  updateOfficeworkingdaySuccess,
} from "./actions"
// Calender Redux States
import {
  ADD_NEW_OFFICE,
  ALL_OFFICE_INVENTORY,
  GET_OFFICES,
  SINGLE_OFFICE_INVENTORY,
  UPDATE_OFFICE,
  UPDATE_OFFICE_WORKINGDAY,
} from "./actionTypes"

function* fetchOffices({ payload: { authtoken, orgid, region } }) {
  try {
    const response = yield call(getofficedata, authtoken, region)
    //  console.log(response);
    yield put(getOfficesSuccess(response))
  } catch (error) {
    yield put(getOfficesFail(error))
    toast.error("Data not fetched")
  }
}

function* fetchOfficesInventory({ payload: { authtoken, officeId } }) {
  try {
    const response = yield call(getSingleOfficeInventory, authtoken, officeId)
    //  console.log(response);
    yield put(getOfficesInventorySuccess(response))
  } catch (error) {
    yield put(getOfficesInventoryFail(error))
    toast.error("Data not fetched")
  }
}

function* addOffice({ payload: { data, history, authtoken } }) {
  try {
    //  console.log(data);
    const response = yield call(postofficedata, data, authtoken)
    //  console.log(response);
    // toast('office added successfully', {
    //   position: "top-right",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    // });
    yield put(addOfficeSuccess(response))
  } catch (error) {
    if (!error.response) {
      toast("office added successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      history.push("/info-office")
    }
    yield put(addOfficeFail(error))
    toast.error(error)
  }
}

function* updateworkingOffice({
  payload: { data, history, authtoken, officeid },
}) {
  try {
    //  console.log('upadate',data);
    const response = yield call(updateofficedata, data, authtoken, officeid)
    //  console.log('office edited',response);
    toast("office Updated successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    yield put(updateOfficeworkingdaySuccess(response))
  } catch (error) {
    if (!error.response) {
      toast("office Updated successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      history.push("/info-office")
    }
    yield put(updateOfficeworkingdayFail(error))
    toast.error(error)
  }
}

function* updateOfficeData({
  payload: { data, history, authtoken, officeid },
}) {
  try {
    const response = yield call(updateOfficeInfo, data, authtoken, officeid)
    toast("office Updated successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    yield put(updateOfficeSuccess(response))
    history.push("/info-office")
  } catch (error) {
    if (!error.response) {
      toast("office Updated successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      history.push("/info-office")
    }
    yield put(updateOfficeFail(error))
    toast.error(error)
  }
}

function* fetchAllOfficeInventory({ payload: { authtoken, pageNo, limit, region } }) {
  try {
    const response = yield call(
      getOfficeInventoryData,
      authtoken,
      pageNo,
      limit, region
    )

    yield put(getAllOfficeInventorySuccess(response))
  } catch (error) {
    //  console.log("27")
    yield put(getAllOfficeInventoryFail(error))
  }
}
function* OfficeSaga() {
  yield takeEvery(GET_OFFICES, fetchOffices)
  yield takeEvery(ADD_NEW_OFFICE, addOffice)
  yield takeEvery(UPDATE_OFFICE_WORKINGDAY, updateworkingOffice)
  yield takeEvery(SINGLE_OFFICE_INVENTORY, fetchOfficesInventory)
  yield takeEvery(UPDATE_OFFICE, updateOfficeData)
  yield takeEvery(ALL_OFFICE_INVENTORY, fetchAllOfficeInventory)
}

export default OfficeSaga
