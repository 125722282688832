import {
  ADD_NEW_USER,
  ADD_USER_FAIL,
  ADD_USER_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  GET_LINE_MANAGER_SUCCESS,
  GET_LINE_MANAGER_FAIL,
  LOADING_ADD_USER,
  UPDATE_SET_ROUTE_FAIL,
  UPDATE_SET_ROUTE_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  STORE_USER_DATA,
  DELETE_USER_ROUTE_SUCCESS,
  DELETE_USER_ROUTE_FAIL,
  USER_INFO_DATA_SUCCESS,
  USER_INFO_DATA_FAIL,
  USER_PERFORMANCE_SUCCESS,
  USER_PERFORMANCE_FAIL,
  SAMPLE_EXCEL_DOWNLOAD_SUCCESS,
  SAMPLE_EXCEL_DOWNLOAD_FAIL,
  GET_TARGET_AND_ACHIEVEMENT,
  GET_TARGET_AND_ACHIEVEMENT_SUCCESS,
  GET_TARGET_AND_ACHIEVEMENT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  lineManager: [],
  userInfo: [],
  userPerformance: [],
  sampleExcel: [],
  editUserInfo: [],
  user: {},
  error: {},
  loading: true,
  userLoading: true,
  lineManagerLoading: true,
  isLoading: false,
  userInfoLoading: true,
  userPerformanceLoading: true,
  sampleExcelLoading: true,
  userTargetAndAchievement: {},
  userTargetAndAchievementLoading: false,
  targetAndAchievementError: "",
}

const UserReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_NEW_USER: {
      return {
        ...state,
        error: {},
        userLoading: false,
      }
    }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false,
        isLoading: true,
      }
    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: true,
        isLoading: false,
      }
    case GET_LINE_MANAGER_SUCCESS:
      return {
        ...state,
        lineManager: action.payload,
        lineManagerLoading: false,
      }
    case GET_LINE_MANAGER_FAIL:
      return {
        ...state,
        error: action.payload,
        lineManagerLoading: true,
      }
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      }

    case GET_USER_FAIL:
      return {
        ...state,
        loading: true,
        error: action.payload,
        //change here
      }

    case ADD_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: [...state.users, action.payload],
        userLoading: true,
      }

    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        userLoading: false,
      }

    case UPDATE_SET_ROUTE_SUCCESS:
      return {
        ...state,
        users: state.users.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
        loading: true,
      }

    case UPDATE_SET_ROUTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
        loading: true,
      }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        products: state.products.filter(
          product => product.id.toString() !== action.payload.id.toString()
        ),
        loading: true,
      }

    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case LOADING_ADD_USER:
      return {
        ...state,
        isLoading: action.payload,
      }
    case STORE_USER_DATA:
      return {
        ...state,
        editUserInfo: action.payload,
      }

    case DELETE_USER_ROUTE_SUCCESS:
      return {
        ...state,
        users: state.users.filter(
          user => user.id.toString() !== action.payload.id.toString()
        ),
        loading: true,
      }

    case DELETE_USER_ROUTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case USER_INFO_DATA_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
        userInfoLoading: false,
      }
    case USER_INFO_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
        userInfoLoading: true,
      }
    case USER_PERFORMANCE_SUCCESS:
      return {
        ...state,
        userPerformance: action.payload,
        userPerformanceLoading: false,
      }
    case USER_PERFORMANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        userPerformanceLoading: true,
      }
    case SAMPLE_EXCEL_DOWNLOAD_SUCCESS:
      return {
        ...state,
        sampleExcel: action.payload,
        sampleExcelLoading: false,
      }
    case SAMPLE_EXCEL_DOWNLOAD_FAIL:
      return {
        ...state,
        error: action.payload,
        sampleExcelLoading: true,
      }

    case GET_TARGET_AND_ACHIEVEMENT:
      return {
        ...state,
        targetAndAchievementError: "",
        userTargetAndAchievementLoading: true,
      }
    case GET_TARGET_AND_ACHIEVEMENT_SUCCESS:
      return {
        ...state,
        targetAndAchievementError: "",
        userTargetAndAchievement: action.payload,
        userTargetAndAchievementLoading: false,
      }
    case GET_TARGET_AND_ACHIEVEMENT_FAIL:
      return {
        ...state,
        targetAndAchievementError: "Data not found",
        userTargetAndAchievementLoading: false,
      }

    default:
      return state
  }
}

export default UserReducer
