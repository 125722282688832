export const GET_TO_ORDER = "GET_TO_ORDER"
export const GET_TO_ORDER_SUCCESS = "GET_TO_ORDER_SUCCESS"
export const GET_TO_ORDER_FAIL = "GET_TO_ORDER_FAIL"


export const GET_TO_SALE = "GET_TO_SALE"
export const GET_TO_SALE_SUCCESS = "GET_TO_SALE_SUCCESS"
export const GET_TO_SALE_FAIL = "GET_TO_SALE_FAIL"

export const GET_SO_BY_TO = "GET_SO_BY_TO"
export const GET_SO_BY_TO_SUCCESS = "GET_SO_BY_TO_SUCCESS"
export const GET_SO_BY_TO_FAIL = "GET_SO_BY_TO_FAIL"

export const GET_SO_BY_RBH = "GET_SO_BY_RBH"
export const GET_SO_BY_RBH_SUCCESS = "GET_SO_BY_RBH_SUCCESS"
export const GET_SO_BY_RBH_FAIL = "GET_SO_BY_RBH_FAIL"

export const GET_TO_DISTRIBUTION = "GET_TO_DISTRIBUTION"
export const GET_TO_DISTRIBUTION_SUCCESS = "GET_TO_DISTRIBUTION_SUCCESS"
export const GET_TO_DISTRIBUTION_FAIL = "GET_TO_DISTRIBUTION_FAIL"


export const GET_TO_PRODUCT = "GET_TO_PRODUCT"
export const GET_TO_PRODUCT_SUCCESS = "GET_TO_PRODUCT_SUCCESS"
export const GET_TO_PRODUCT_FAIL = "GET_TO_PRODUCT_FAIL"


export const ADD_STOCK_TO = "ADD_STOCK_TO"
export const ADD_STOCK_TO_SUCCESS = "ADD_STOCK_TO_SUCCESS"
export const ADD_STOCK_TO_FAIL = "ADD_STOCK_TO_FAIL"

export const GET_INVENTORY = "GET_INVENTORY"
export const GET_INVENTORY_SUCCESS = "GET_INVENTORY_SUCCESS"
export const GET_INVENTORY_FAIL = "GET_INVENTORY_FAIL"

export const GET_REQUISITION = "GET_REQUISITION"
export const GET_REQUISITION_SUCCESS = "GET_REQUISITION_SUCCESS"
export const GET_REQUISITION_FAIL = "GET_REQUISITION_FAIL"

export const GET_STOCK_REPORT = "GET_STOCK_REPORT"
export const GET_STOCK_REPORT_SUCCESS = "GET_STOCK_REPORT_SUCCESS"
export const GET_STOCK_REPORT_FAIL = "GET_STOCK_REPORT_FAIL"

export const GET_ORDER_REPORT = "GET_ORDER_REPORT"
export const GET_ORDER_REPORT_SUCCESS = "GET_ORDER_REPORT_SUCCESS"
export const GET_ORDER_REPORT_FAIL = "GET_ORDER_REPORT_FAIL"

export const GET_SALES_REPORT = "GET_SALES_REPORT"
export const GET_SALES_REPORT_SUCCESS = "GET_SALES_REPORT_SUCCESS"
export const GET_SALES_REPORT_FAIL = "GET_SALES_REPORT_FAIL"

export const GET_SINGLE_ORDER = "GET_SINGLE_ORDER"
export const GET_SINGLE_ORDER_SUCCESS = "GET_SINGLE_ORDER_SUCCESS"
export const GET_SINGLE_ORDER_FAIL = "GET_SINGLE_ORDER_FAIL"

export const GET_TO_DELIVERABLES = "GET_TO_DELIVERABLES"
export const GET_TO_DELIVERABLES_SUCCESS = "GET_TO_DELIVERABLES_SUCCESS"
export const GET_TO_DELIVERABLES_FAIL = "GET_TO_DELIVERABLES_FAIL"


export const GET_DAY_OC_REPORT = "GET_DAY_OC_REPORT"
export const GET_DAY_OC_REPORT_SUCCESS = "GET_DAY_OC_REPORT_SUCCESS"
export const GET_DAY_OC_REPORT_FAIL = "GET_DAY_OC_REPORT_FAIL"

export const STOCK_REPORT_DETAILS = "STOCK_REPORT_DETAILS"
export const STOCK_REPORT_DETAILS_SUCCESS = "STOCK_REPORT_DETAILS_SUCCESS"
export const STOCK_REPORT_DETAILS_FAIL = "STOCK_REPORT_DETAILS_FAIL"

export const DO_DAY_CLOSE = "DO_DAY_CLOSE"
export const DO_DAY_CLOSE_SUCCESS = "DO_DAY_CLOSE_SUCCESS"
export const DO_DAY_CLOSE_FAIL = "DO_DAY_CLOSE_FAIL"

export const UPDATE_STOCK = "UPDATE_STOCK"
export const UPDATE_STOCK_SUCCESS = "UPDATE_STOCK_SUCCESS"
export const UPDATE_STOCK_FAIL = "UPDATE_STOCK_FAIL"

export const STORE_TO_DATA = "STORE_TO_DATA"
