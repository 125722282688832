import {
  ADD_NEW_OFFICE, ADD_OFFICE_FAIL, ADD_OFFICE_SUCCESS, GET_OFFICES_FAIL, GET_OFFICES_SUCCESS,
  UPDATE_OFFICE_WORKINGDAY, UPDATE_OFFICE_WORKINGDAY_FAIL, UPDATE_OFFICE_WORKINGDAY_SUCCESS,STORE_OFFICE_DATA,
  SINGLE_OFFICE_INVENTORY_SUCCESS,SINGLE_OFFICE_INVENTORY_FAIL, ALL_OFFICE_INVENTORY_SUCCESS, ALL_OFFICE_INVENTORY_FAIL
} from "./actionTypes"

const INIT_STATE = {
  offices: [],
  officeInventory: [],
  allOfficeInventory: [],
  error: {},
  loading: false,
  isLoading: false,
  officeInventoryLoading: true,
  allOfficeInventoryLoading: true
}

const OfficeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    
    case GET_OFFICES_SUCCESS:
      return {
        ...state,
        offices: action.payload,
        loading: true,
      }
    case GET_OFFICES_FAIL:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      }
    case ADD_NEW_OFFICE:
      return {
        ...state,
        error:{},
        loading: true,
      }
    case ADD_OFFICE_SUCCESS:
      return {
        ...state,
        users: [...state.offices, action.payload],
        loading: false,
      }

    case ADD_OFFICE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        loading: true,
      }
      case UPDATE_OFFICE_WORKINGDAY:
        return {
          ...state,
          loading: true,
        }

      case UPDATE_OFFICE_WORKINGDAY_SUCCESS:
        return {
          ...state,
          message: action.payload.message,
          loading: true,
        }
  
      case UPDATE_OFFICE_WORKINGDAY_FAIL:
        return {
          ...state,
          error: action.payload.message,
          loading: false
        }
        case STORE_OFFICE_DATA:
          return {
            ...state,
            [action.payload.name]: action.payload.data,
          }
          case SINGLE_OFFICE_INVENTORY_SUCCESS:
            return {
              ...state,
              officeInventory: action.payload,
              officeInventoryLoading: false,
            }
          case SINGLE_OFFICE_INVENTORY_FAIL:
            return {
              ...state,
              error: action.payload.message,
              officeInventoryLoading: true,
            }
            case ALL_OFFICE_INVENTORY_SUCCESS:
              return {
                ...state,
                allOfficeInventory: action.payload,
                allOfficeInventoryLoading: false,
              }
        
            case ALL_OFFICE_INVENTORY_FAIL:
              return {
                ...state,
                error: action.payload.message,
                allOfficeInventoryLoading: false,
              }
    default:
      return state
  }
}

export default OfficeReducer
