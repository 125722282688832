//GET
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"
//GET SINGLE USER

export const GET_USER = "GET_USER"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAIL = "GET_USER_FAIL"

//ADD TERRITORY
export const ADD_NEW_TERRITORY = "ADD_NEW_TERRITORY"
export const ADD_TERRITORY_SUCCESS = "ADD_TERRITORY_SUCCESS"
export const ADD_TERRITORY_FAIL = "ADD_TERRITORY_FAIL"

// ADD AREA
export const ADD_NEW_AREA = "ADD_NEW_AREA"
export const ADD_AREA_SUCCESS = "ADD_AREA_SUCCESS"
export const ADD_AREA_FAIL = "ADD_AREA_FAIL"

//ADD REGION
export const ADD_NEW_REGION = "ADD_NEW_REGION"
export const ADD_REGION_SUCCESS = "ADD_REGION_SUCCESS"
export const ADD_REGION_FAIL = "ADD_REGION_FAIL"

//ADD GEO
export const ADD_NEW_GEO = "ADD_NEW_GEO"
export const ADD_GEO_SUCCESS = "ADD_GEO_SUCCESS"
export const ADD_GEO_FAIL = "ADD_GEO_FAIL"

//Add Route
export const ADD_NEW_ROUTE = "ADD_NEW_ROUTE"
export const ADD_ROUTE_SUCCESS = "ADD_ROUTE_SUCCESS"
export const ADD_ROUTE_FAIL = "ADD_ROUTE_FAIL"

//Get Route
export const GET_ROUTES = "GET_ROUTES"
export const GET_ROUTES_SUCCESS = "GET_ROUTES_SUCCESS"
export const GET_ROUTES_FAIL = "GET_ROUTES_FAIL"

export const GET_GEO_ROUTES = "GET_GEO_ROUTES"
export const GET_GEO_ROUTES_SUCCESS = "GET_GEO_ROUTES_SUCCESS"
export const GET_GEO_ROUTES_FAIL = "GET_GEO_ROUTES_FAIL"

export const GET_DETAILS_ROUTES = "GET_DETAILS_ROUTES"
export const GET_DETAILS_ROUTES_SUCCESS = "GET_DETAILS_ROUTES_SUCCESS"
export const GET_DETAILS_ROUTES_FAIL = "GET_DETAILS_ROUTES_FAIL"

export const GET_ROUTE_STORE = "GET_ROUTE_STORE"
export const GET_ROUTE_STORE_SUCCESS = "GET_ROUTE_STORE_SUCCESS"
export const GET_ROUTE_STORE_FAIL = "GET_ROUTE_STORE_FAIL"
//Get Area

export const GET_AREAS = "GET_AREAS"
export const GET_AREAS_SUCCESS = "GET_AREAS_SUCCESS"
export const GET_AREAS_FAIL = "GET_AREAS_FAIL"
//Get Region

export const GET_REGIONS = "GET_REGIONS"
export const GET_REGIONS_SUCCESS = "GET_REGIONS_SUCCESS"
export const GET_REGIONS_FAIL = "GET_REGIONS_FAIL"

//Get Territory

export const GET_TERRITORYS = "GET_TERRITORYS"
export const GET_TERRITORYS_SUCCESS = "GET_TERRITORYS_SUCCESS"
export const GET_TERRITORYS_FAIL = "GET_TERRITORYS_FAIL"

//Get Geo

export const GET_GEOS = "GET_GEOS"
export const GET_GEOS_SUCCESS = "GET_GEOS_SUCCESS"
export const GET_GEOS_FAIL = "GET_GEOS_FAIL"

// Update
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

//Delete
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"

export const DELETE_GEO = "DELETE_GEO "
export const DELETE_GEO_SUCCESS = "DELETE_GEO_SUCCESS"
export const DELETE_GEO_FAIL = "DELETE_GEO_FAIL"
export const GET_DATA = "GET_DATA"


export const GET_ROUTES_INFO = "GET_ROUTES_INFO"
export const GET_ROUTES_INFO_SUCCESS = "GET_ROUTES_INFO_SUCCESS"
export const GET_ROUTES_INFO_FAIL = "GET_ROUTES_INFO_FAIL"