import {
 
    GET_REQUISITION_RBH_SUCCESS,
    GET_REQUISITION_RBH_FAIL,
    GET_RBH_DISTRIBUTION_SUCCESS,
    GET_RBH_DISTRIBUTION_FAIL,
    GET_REQUEST_PRODUCT_SUCCESS,
    GET_REQUEST_PRODUCT_FAIL,
    GET_RBH_ORDER_SUCCESS,
    GET_RBH_ORDER_FAIL,
    GET_RBH_SALE_SUCCESS,
    GET_RBH_SALE_FAIL,
    GET_ORDER_REPORT_RBH_SUCCESS,
    GET_ORDER_REPORT_RBH_FAIL,
    GET_SALES_REPORT_RBH_SUCCESS,
    GET_SALES_REPORT_RBH_FAIL,
    STOCK_ACCEPT,
    ADD_STOCK_RBH,
    STORE_RBH_DATA
  } from './actionTypes'
  const INIT_STATE = {

    requisitionRBH: [],
    rbhDistribution: [],
    requestProduct: [],
    rbhOrder: [],
    rbhSale: [],
    rbhOrderReport:[],
    rbhSalesReport: [],
    updatedRequestProduct: [],
    requisitionRBHLoading: true,
    rbhDistributionLoading: true,
    requestProductLoading: true,
    rbhOrderLoading: true,
    rbhSaleLoading: true,
    adding : false,
    stockAddLoading: false

  
  }
  
  
  const RbhReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      
                  case GET_REQUISITION_RBH_SUCCESS:
                    return {
                      ...state,
                      requisitionRBH: action.payload,
                      requisitionRBHLoading: false,
                     
                    }
                  case GET_REQUISITION_RBH_FAIL:
                    return {
                      ...state,
                      error: action.payload,
                      requisitionRBHLoading: false,  
              
                    }
                    case GET_RBH_DISTRIBUTION_SUCCESS:
                    return {
                      ...state,
                      rbhDistribution: action.payload,
                      rbhDistributionLoading: false,
                     
                    }
                  case GET_RBH_DISTRIBUTION_FAIL:
                    return {
                      ...state,
                      error: action.payload,
                      rbhDistributionLoading: false,  
              
                    }
                    case GET_REQUEST_PRODUCT_SUCCESS:
                      return {
                        ...state,
                        requestProduct: action.payload,
                        requestProductLoading: false,
                       
                      }
                    case GET_REQUEST_PRODUCT_FAIL:
                      return {
                        ...state,
                        error: action.payload,
                        requestProductLoading: false,  
                
                      }
                      case GET_RBH_ORDER_SUCCESS:
                        return {
                          ...state,
                          rbhOrder: action.payload,
                          rbhOrderLoading: false,
                         
                        }
                      case GET_RBH_ORDER_FAIL:
                        return {
                          ...state,
                          error: action.payload,
                          rbhOrderLoading: false,  
                  
                        }
                        case GET_RBH_SALE_SUCCESS:
                          return {
                            ...state,
                            rbhSale: action.payload,
                            rbhSaleLoading: false,
                           
                          }
                        case GET_RBH_SALE_FAIL:
                          return {
                            ...state,
                            error: action.payload,
                            rbhSaleLoading: false,  
                    
                          }
                          case GET_ORDER_REPORT_RBH_SUCCESS:
                            return {
                              ...state,
                              rbhOrderReport: action.payload,
                              
                             
                            }
                          case GET_ORDER_REPORT_RBH_FAIL:
                            return {
                              ...state,
                              error: action.payload,
                              
                      
                            }
                            case GET_SALES_REPORT_RBH_SUCCESS:
                              return {
                                ...state,
                                rbhSalesReport: action.payload,
                                
                               
                              }
                            case GET_SALES_REPORT_RBH_FAIL:
                              return {
                                ...state,
                                error: action.payload,
                                
                        
                              }
                              case STOCK_ACCEPT:
                                return {
                                  ...state,
                                  adding: true,
                                };
                                case ADD_STOCK_RBH:
                                  return {
                                    ...state,
                                    stockAddLoading: true,
                                  };
               
                case STORE_RBH_DATA:
                  return {
                    ...state,
                    [action.payload.name]: action.payload.data,
                 
                  
                  }
    
      default:
        return state
    }
  }
  
  export default RbhReducer