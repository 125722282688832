export const ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT"
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS"
export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL"

export const LOADING_ADD_PRODUCT = "LOADING_ADD_PRODUCT"

// GET PRODUCTS
export const GET_PRODUCTS = "GET_PRODUCTS"
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL"

// GET PRODUCT
export const GET_PRODUCT = "GET_PRODUCT"
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS"
export const GET_PRODUCT_FAIL = "GET_PRODUCT_FAIL"

//UPDATE PRODUCT
export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS"
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL"

export const GET_TOTAL_PRODUCT_QUANTITY = "GET_TOTAL_PRODUCT_QUANTITY"
export const GET_TOTAL_PRODUCT_QUANTITY_SUCCESS = "GET_TOTAL_PRODUCT_QUANTITY_SUCCESS"
export const GET_TOTAL_PRODUCT_QUANTITY_FAIL = "GET_TOTAL_PRODUCT_QUANTITY_FAIL"
