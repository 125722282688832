import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { axiosRegistration } from "../../../helpers/api_helper"
//Include Both Helper File with needed methods
import { registerUserFailed, registerUserSuccessful } from "./actions"
//Account Redux states
import { REGISTER_USER } from "./actionTypes"

// initialize relavant method of both Auth

const asyncRegistration = async (
  email,
  phoneNumber,
  userName,
  password,
  confirmPassword
) => {
  try {
    const response = await axiosRegistration(
      userName,
      email,
      phoneNumber,
      password,
      confirmPassword
    )
    //  console.log("response from backend", response)
    return response.data
  } catch (error) {
    //  console.log("response from error", error.response)
    return error.response.data
  }
}

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history, toast } }) {
  try {
    //  console.log("all user history", user, history)
    const { email, phoneNumber, userName, password, confirmPassword } = user
    //  console.log(
    //   "user data",
    //   email,
    //   phoneNumber,
    //   userName,
    //   password,
    //   confirmPassword
    // )
    //  console.log("user data", user)
    const registerResponse = yield call(
      asyncRegistration,
      email,
      phoneNumber,
      userName,
      password,
      confirmPassword
    )
    if (registerResponse.status == "success") {
      //  console.log("hello bd", registerResponse)
      yield put(
        registerUserSuccessful(
          "A token has been send at your mail, please check"
        )
      )
      localStorage.setItem("useremail", email)
      // yield put(registerUserSuccessful())

      history.push("/emailverify")
    } else {
      //  console.log("hello bd", registerResponse.message)
      yield put(registerUserFailed(registerResponse.message))
      // yield put(registerUserFailed())
    }
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
