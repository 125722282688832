import {

    ADD_PJP_SUCCESS,
    ADD_PJP_FAIL, GET_ALLPJP_SUCCESS,
    GET_ALLPJP_FAIL
  } from "./actionTypes"
  
  const INIT_STATE = {
      
      pjps: [],
      pjp:{},
      error: {},
      loading: true,
  
    }
  
    const PJPReducer = (state = INIT_STATE, action) => {
      switch (action.type) {
        case ADD_PJP_SUCCESS:
              return {
                ...state,
                loading: false,
                pjps: [...state.pjps, action.payload],    
              }
        
        case ADD_PJP_FAIL:
              return {
                ...state,
                error: action.payload,
                loading: false,
              }
        case GET_ALLPJP_SUCCESS:
          return {
            ...state,
            pjps: action.payload,
            loading: false,
          }
        case GET_ALLPJP_FAIL:
          return {
            ...state,
            loading: true,
            error: action.payload,
          }
        default:
          return state
      }
    }
    
  export default PJPReducer