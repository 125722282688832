import {
  ADD_CONSUMER_OFFER, ADD_CONSUMER_OFFER_FAIL, ADD_CONSUMER_OFFER_SUCCESS,
  ADD_RETAILER_OFFER, ADD_RETAILER_OFFER_SUCCESS, ADD_RETAILER_OFFER_FAIL,
  ADD_OFFER, ADD_OFFER_FAIL, ADD_OFFER_SUCCESS,
  GET_OFFER, GET_OFFERS, GET_OFFERS_FAIL, GET_OFFERS_SUCCESS, GET_OFFER_FAIL, GET_OFFER_SUCCESS,
  GET_OFFER_DETAILS,GET_OFFER_DETAILS_SUCCESS,GET_OFFER_DETAILS_FAIL
} from './actionTypes.js'
export const addNewOffer = (data, history, authtoken) => ({
  type: ADD_OFFER,
  payload: { data, history, authtoken },

})

export const addOfferSuccess = data => ({
  type: ADD_OFFER_SUCCESS,
  payload: data,
})

export const addOfferFail = error => ({
  type: ADD_OFFER_FAIL,
  payload: error,
})
export const addConsumerOffer = (data, history, authtoken, id) => ({
  type: ADD_CONSUMER_OFFER,
  payload: { data, history, authtoken, id },

})

export const addConsumerOfferSuccess = data => ({
  type: ADD_CONSUMER_OFFER_SUCCESS,
  payload: data,
})

export const addConsumerOfferFail = error => ({
  type: ADD_CONSUMER_OFFER_FAIL,
  payload: error,
})
export const addRetailerOffer = (data, history, authtoken, id) => ({
  type: ADD_RETAILER_OFFER,
  payload: { data, history, authtoken, id },

})

export const addRetailerOfferSuccess = data => ({
  type: ADD_RETAILER_OFFER_SUCCESS,
  payload: data,
})

export const addRetailerOfferFail = error => ({
  type: ADD_RETAILER_OFFER_FAIL,
  payload: error,
})

export const getOffers = (authtoken) => ({
  type: GET_OFFERS,
  payload: { authtoken },
})


export const getOffersSuccess = (data, authtoken) => ({
  type: GET_OFFERS_SUCCESS,
  payload: { data, authtoken },
})

export const getOffersFail = error => ({
  type: GET_OFFERS_FAIL,
  payload: error,
})

export const getOffersDetails = (authtoken,id) => ({
  type: GET_OFFER_DETAILS,
  payload: { authtoken, id },
})


export const getOffersDetailsSuccess = (data, authtoken) => ({
  type: GET_OFFER_DETAILS_SUCCESS,
  payload: { data, authtoken },
})

export const getOffersDetailsFail = error => ({
  type: GET_OFFER_DETAILS_FAIL,
  payload: error,
})
export const getOffer = id => ({
  type: GET_OFFER,
  payload: id,
})



export const getOfferSuccess = (data, authtoken) => ({
  type: GET_OFFER_SUCCESS,
  payload: { data, authtoken },
})

export const getOfferFail = error => ({
  type: GET_OFFER_FAIL,
  payload: error,
})