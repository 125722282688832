export const GET_REQUISITION_RBH = "GET_REQUISITION_RBH"
export const GET_REQUISITION_RBH_SUCCESS = "GET_REQUISITION_RBH_SUCCESS"
export const GET_REQUISITION_RBH_FAIL = "GET_REQUISITION_RBH_FAIL"

export const GET_RBH_DISTRIBUTION = "GET_RBH_DISTRIBUTION"
export const GET_RBH_DISTRIBUTION_SUCCESS = "GET_RBH_DISTRIBUTION_SUCCESS"
export const GET_RBH_DISTRIBUTION_FAIL = "GET_TO_DISTRIBUTION_FAIL"

export const GET_REQUEST_PRODUCT = "GET_REQUEST_PRODUCT"
export const GET_REQUEST_PRODUCT_SUCCESS = "GET_REQUEST_PRODUCT_SUCCESS"
export const GET_REQUEST_PRODUCT_FAIL = "GET_REQUEST_PRODUCT_FAIL"

export const GET_RBH_ORDER = "GET_RBH_ORDER"
export const GET_RBH_ORDER_SUCCESS = "GET_RBH_ORDER_SUCCESS"
export const GET_RBH_ORDER_FAIL = "GET_RBH_ORDER_FAIL"


export const GET_RBH_SALE = "GET_RBH_SALE"
export const GET_RBH_SALE_SUCCESS = "GET_RBH_SALE_SUCCESS"
export const GET_RBH_SALE_FAIL = "GET_RBH_SALE_FAIL"

export const STOCK_ACCEPT = "STOCK_ACCEPT"
export const STOCK_ACCEPT_SUCCESS = "STOCK_ACCEPT_SUCCESS"
export const STOCK_ACCEPT_FAIL = "STOCK_ACCEPT_FAIL"


export const GET_ORDER_REPORT_RBH = "GET_ORDER_REPORT_RBH"
export const GET_ORDER_REPORT_RBH_SUCCESS = "GET_ORDER_REPORT_RBH_SUCCESS"
export const GET_ORDER_REPORT_RBH_FAIL = "GET_ORDER_REPORT_RBH_FAIL"

export const GET_SALES_REPORT_RBH = "GET_SALES_REPORT_RBH"
export const GET_SALES_REPORT_RBH_SUCCESS = "GET_SALES_REPORT_RBH_SUCCESS"
export const GET_SALES_REPORT_RBH_FAIL = "GET_SALES_REPORT_RBH_FAIL"

export const STORE_RBH_DATA = "STORE_RBH_DATA"

export const ADD_STOCK_RBH = "ADD_STOCK_RBH"
export const ADD_STOCK_RBH_SUCCESS = "ADD_STOCK_RBH_SUCCESS"
export const ADD_STOCK_RBH_FAIL = "ADD_STOCK_RBH_FAIL"
