import {
  GET_TO_ORDER_SUCCESS,
  GET_TO_ORDER_FAIL,
  GET_TO_SALE_SUCCESS,
  GET_TO_SALE_FAIL,
  GET_SO_BY_TO_SUCCESS,
  GET_SO_BY_TO_FAIL,
  GET_TO_DISTRIBUTION_SUCCESS,
  GET_TO_DISTRIBUTION_FAIL,
  GET_TO_PRODUCT_SUCCESS,
  GET_TO_PRODUCT_FAIL,
  GET_INVENTORY_SUCCESS,
  GET_INVENTORY_FAIL,
  GET_REQUISITION_SUCCESS,
  GET_REQUISITION_FAIL,
  GET_STOCK_REPORT_SUCCESS,
  GET_STOCK_REPORT_FAIL,
  GET_ORDER_REPORT_SUCCESS,
  GET_ORDER_REPORT_FAIL,
  GET_SALES_REPORT_SUCCESS,
  GET_SALES_REPORT_FAIL,
  GET_SINGLE_ORDER_SUCCESS,
  GET_SINGLE_ORDER_FAIL,
  GET_TO_DELIVERABLES_SUCCESS,
  GET_TO_DELIVERABLES_FAIL,
  GET_DAY_OC_REPORT_SUCCESS,
  GET_DAY_OC_REPORT_FAIL,
  STOCK_REPORT_DETAILS_SUCCESS,
  STOCK_REPORT_DETAILS_FAIL,
  ADD_STOCK_TO,
  STORE_TO_DATA,
  GET_SO_BY_RBH_SUCCESS,
  GET_SO_BY_RBH_FAIL,
} from "./actionTypes"
const INIT_STATE = {
  OrderListTo: [],
  SaleListTo: [],
  SoByTo: [],
  SoByRBH: [],
  toDistribution: [],
  toProducts: [],
  updatedToProduct: [],
  InventoryList: [],
  requisitionTo: [],
  stockReport: [],
  orderReport: [],
  salesReport: [],
  singleOrderTo: [],
  toDeliverables: [],
  ocReport: [],
  reportDetails: [],
  SaleListToLoading: true,
  OrderListToLoading: true,
  SoByToLoading: true,
  SoByRBHLoading: true,
  toDistributionLoading: true,
  toProductsLoading: true,
  InventoryListLoading: true,
  requisitionToLoading: true,
  stockReportLoading: true,
  orderReportLoading: true,
  salesReportLoading: true,
  singleOrderToLoading: true,
  toDeliverablesLoading: true,
  ocReportLoading: true,
  reportDetailsLoading: true,
  adding : false
}

const ToReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TO_ORDER_SUCCESS:
      return {
        ...state,
        OrderListTo: action.payload,
        OrderListToLoading: false,
      }
    case GET_TO_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        OrderListToLoading: false,
      }
    case GET_TO_SALE_SUCCESS:
      return {
        ...state,
        SaleListTo: action.payload,
        SaleListToLoading: false,
      }
    case GET_TO_SALE_FAIL:
      return {
        ...state,
        error: action.payload,
        SaleListToLoading: false,
      }
    case GET_SO_BY_TO_SUCCESS:
      return {
        ...state,
        SoByTo: action.payload,
        SoByToLoading: false,
      }
    case GET_SO_BY_TO_FAIL:
      return {
        ...state,
        error: action.payload,
        SoByToLoading: false,
      }
      case GET_SO_BY_RBH_SUCCESS:
        return {
          ...state,
          SoByRBH: action.payload,
          SoByRBHLoading: false,
        }
      case GET_SO_BY_RBH_FAIL:
        return {
          ...state,
          error: action.payload,
          SoByToLoading: false,
        }
    case GET_TO_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        toDistribution: action.payload,
        toDistributionLoading: false,
      }
    case GET_TO_DISTRIBUTION_FAIL:
      return {
        ...state,
        error: action.payload,
        toDistributionLoading: false,
      }
    case GET_TO_PRODUCT_SUCCESS:
      return {
        ...state,
        toProducts: action.payload,
        toProductsLoading: false,
      }
    case GET_TO_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
        toProductsLoading: false,
      }
    case GET_INVENTORY_SUCCESS:
      return {
        ...state,
        InventoryList: action.payload,
        InventoryListLoading: false,
      }
    case GET_INVENTORY_FAIL:
      return {
        ...state,
        error: action.payload,
        InventoryListLoading: false,
      }
    case GET_REQUISITION_SUCCESS:
      return {
        ...state,
        requisitionTo: action.payload,
        requisitionToLoading: false,
      }
    case GET_REQUISITION_FAIL:
      return {
        ...state,
        error: action.payload,
        requisitionToLoading: false,
      }
    case GET_STOCK_REPORT_SUCCESS:
      return {
        ...state,
        stockReport: action.payload,
        stockReportLoading: false,
      }
    case GET_STOCK_REPORT_FAIL:
      return {
        ...state,
        error: action.payload,
        stockReportLoading: false,
      }
    case GET_ORDER_REPORT_SUCCESS:
      return {
        ...state,
        orderReport: action.payload,
        orderReportLoading: false,
      }
    case GET_ORDER_REPORT_FAIL:
      return {
        ...state,
        error: action.payload,
        orderReportLoading: false,
      }
    case GET_SALES_REPORT_SUCCESS:
      return {
        ...state,
        salesReport: action.payload,
        salesReportLoading: false,
      }
    case GET_SALES_REPORT_FAIL:
      return {
        ...state,
        error: action.payload,
        salesReportLoading: false,
      }
    case GET_SINGLE_ORDER_SUCCESS:
      return {
        ...state,
        singleOrderTo: action.payload,
        singleOrderToLoading: false,
      }
    case GET_SINGLE_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        singleOrderToLoading: false,
      }
    case GET_TO_DELIVERABLES_SUCCESS:
      return {
        ...state,
        toDeliverables: action.payload,
        toDeliverablesLoading: false,
      }
    case GET_TO_DELIVERABLES_FAIL:
      return {
        ...state,
        error: action.payload,
        toDeliverablesLoading: false,
      }
    case GET_DAY_OC_REPORT_SUCCESS:
      return {
        ...state,
        ocReport: action.payload,
        ocReportLoading: false,
      }
    case GET_DAY_OC_REPORT_FAIL:
      return {
        ...state,
        error: action.payload,
        ocReportLoading: false,
      }
    case STOCK_REPORT_DETAILS_SUCCESS:
      return {
        ...state,
        reportDetails: action.payload,
        reportDetailsLoading: false,
      }
    case STOCK_REPORT_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        reportDetailsLoading: false,
      }
      case ADD_STOCK_TO:
        return {
          ...state,
          adding: true,
        };
    case STORE_TO_DATA:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      }

    default:
      return state
  }
}

export default ToReducer
