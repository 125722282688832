import { all, call, fork, put, takeEvery } from "redux-saga/effects";
//Include Both Helper File with needed methods
import {
    getEarningChartsData as getEarningChartsDataApi, topSellingData as topSellingDataApi
} from "../../helpers/backend_helper";
import { apiFail, apiSuccess } from "./actions";
// Crypto Redux States
import { GET_EARNING_DATA, GET_TOP_SELLING_PRODUCT } from "./actionType";



function* getSellingData({ payload: month }) {
    try {
        var response = yield call(topSellingDataApi, month);
        yield put(apiSuccess(GET_TOP_SELLING_PRODUCT, response));
    } catch (error) {
        yield put(apiFail(GET_TOP_SELLING_PRODUCT, error));
    }
}


function* getEarningChartsData({ payload: month }) {
    try {
        var response = yield call(getEarningChartsDataApi, month);
        yield put(apiSuccess(GET_EARNING_DATA, response));
    } catch (error) {
        yield put(apiFail(GET_EARNING_DATA, error));
    }
}

export function* watchGetSellingdata() {
    yield takeEvery(GET_TOP_SELLING_PRODUCT, getSellingData);
}

export function* watchGetEarningChartsData() {
    yield takeEvery(GET_EARNING_DATA, getEarningChartsData);
}


function* dashboardSaasSaga() {
    yield all([fork(watchGetSellingdata)],
        yield all([fork(watchGetEarningChartsData)])
    );
}

export default dashboardSaasSaga;
