import { all, call, fork, put, takeEvery } from "redux-saga/effects"

//VerifyEmail Redux state
import { VERIFY_EMAILS } from "./actionTypes"

import { axiosVerifyEmail } from "../../../helpers/api_helper"
import { loginSuccess } from "../login/actions"
import { verifyEmailFail, verifyEmailSuccess } from "./actions"

const asynVerifyEmail = async (email, verifyCode) => {
  try {
    //  console.log("heello data", email, verifyCode)
    const response = await axiosVerifyEmail(email, verifyCode)
    //  console.log("response", response.data)
    return response.data
  } catch (err) {
    //  console.log("response", err.response.data)
    return err.response.data
  }
}

function* workerVerifyEmail({ payload: { values, history } }) {
  //  console.log("from saga")
  //  console.log("values and hsitroy", values, history)
  try {
    const response = yield call(
      asynVerifyEmail,
      values.email,
      values.verifycode
    )
    //  console.log("respone data", response)
    if (response.status == "success") {
      yield put(
        loginSuccess(
          response.user.findUserOfEmail.fullName,
          response.token,
          response.user.findUserOfEmail.email,
          response.user.findUserOfEmail.role
        )
      )
      yield put(verifyEmailSuccess("Account Verified"))
      history.push("/studentDashboard")
    } else {
      yield put(verifyEmailFail(response.message))
    }
  } catch (err) {
    //  console.log("error data", err)
  }
}

export function* watchEmailSaga() {
  yield takeEvery(VERIFY_EMAILS, workerVerifyEmail)
}

function* verifyEmailSaga() {
  yield all([fork(watchEmailSaga)])
}
export default verifyEmailSaga
