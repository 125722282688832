import { toast } from "react-toastify"
import { call, delay, put, takeEvery } from "redux-saga/effects"
import {
  addProduct,
  getAllProducts,
  getProductQuantityData,
  getSingleProduct,
  updateProduct,
} from "../../helpers/backend_helper"
import {
  ADD_NEW_PRODUCT,
  GET_PRODUCT,
  GET_PRODUCTS,
  GET_TOTAL_PRODUCT_QUANTITY,
  UPDATE_PRODUCT,
} from "./actionTypes"
import {
  addProductFail,
  addProductSuccess,
  getProductFail,
  getProductSuccess,
  getProductsFail,
  getProductsSuccess,
  getTotalProductAmountFail,
  getTotalProductAmountSuccess,
  updateProductFail,
} from "./actions"

function* fetchProducts({ payload: { authtoken, orgid } }) {
  try {
    delay(500)
    const response = yield call(getAllProducts, authtoken, orgid)
    yield put(getProductsSuccess(response))
    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(getProductsFail(error))
    //  console.log("hi")
  }
}

function* fetchProduct({ payload: { authtoken, id } }) {
  try {
    //  console.log(authtoken)
    //  console.log(id)
    const response = yield call(getSingleProduct, authtoken, id)
    yield put(getProductSuccess(response))
  } catch (error) {
    //  console.log("27")
    yield put(getProductFail(error))
  }
}

function* onAddNewProduct({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(addProduct, data, authtoken)
    //  console.log("hi")
    //  console.log(response)
    yield put(addProductSuccess(response))
    toast("🦄 Product added successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    history.push("/details-product")
  } catch (error) {
    if (!error.response) {
      toast("🦄 Product added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      history.push("/details-product")
    } else {
      let message = error.response.data.message
      yield put(addProductFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}
function* onUpdateProduct({
  payload: { formdata, authtoken, productID, history },
}) {
  try {
    const response = yield call(updateProduct, formdata, authtoken, productID)
    //  console.log(`file: saga.js ~ line 95 ~ response`, response)
    // yield put(updateProductSuccess(response))
    if (response.status === "success") {
      history.push("/details-product")
    }
  } catch (error) {
    //  console.log("update error", error.response)
    yield put(updateProductFail(error))
  }
}

function* fetchTotalQuantityProduct({ payload: { authtoken } }) {
  try {
    //  console.log(authtoken)

    const response = yield call(getProductQuantityData, authtoken)
    yield put(getTotalProductAmountSuccess(response))
  } catch (error) {
    //  console.log("27")
    yield put(getTotalProductAmountFail(error))
  }
}

function* ProductSaga() {
  yield takeEvery(GET_PRODUCTS, fetchProducts)
  yield takeEvery(GET_PRODUCT, fetchProduct)
  yield takeEvery(ADD_NEW_PRODUCT, onAddNewProduct)
  yield takeEvery(UPDATE_PRODUCT, onUpdateProduct)
  yield takeEvery(GET_TOTAL_PRODUCT_QUANTITY, fetchTotalQuantityProduct)
}

export default ProductSaga
