

// ADD BRAND
export const ADD_NEW_BRAND = "ADD_NEW_BRAND"
export const ADD_BRAND_SUCCESS = "ADD_BRAND_SUCCESS"
export const ADD_BRAND_FAIL = "ADD_BRAND_FAIL"


export const GET_BRANDS = "GET_BRANDS"
export const GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS"
export const GET_BRANDS_FAIL = "GET_BRANDS_FAIL"

//Get BRAND
export const GET_BRAND = "GET_BRAND"
export const GET_BRAND_SUCCESS = "GET_BRAND_SUCCESS"
export const GET_BRAND_FAIL = "GET_BRAND_FAIL"

// Update
export const UPDATE_BRAND = "UPDATE_BRAND"
export const UPDATE_BRAND_SUCCESS =
    "UPDATE_BRAND_SUCCESS"
export const UPDATE_BRAND_FAIL = "UPDATE_BRAND_FAIL"

//Delete
export const DELETE_BRAND = "DELETE_BRAND"
export const DELETE_BRAND_SUCCESS =
    "DELETE_BRAND_SUCCESS"
export const DELETE_BRAND_FAIL = "DELETE_BRAND_FAIL"

export const GET_BRANDS_STATUS = "GET_BRANDS_STATUS"
export const GET_BRANDS_STATUS_SUCCESS = "GET_BRANDS_STATUS_SUCCESS"
export const GET_BRANDS_STATUS_FAIL = "GET_BRANDS_STATUS_FAIL"


