import { toast } from "react-toastify"
import { call, delay, put, takeEvery } from "redux-saga/effects"
//Include Both Helper File with needed methods
import {
  addUser,
  deleteUser,
  deleteUserRoute,
  getAllUserInfo,
  getLineManager,
  getSampleDataExcel,
  getTargetAndAchievementAPI,
  getUser,
  getUsers,
  getUsersPerformance,
  updateRouteUser,
  updateUser,
} from "../../helpers/backend_helper.js"
import {
  addUserFail,
  addUserSuccess,
  deleteRouteUserFail,
  deleteRouteUserSuccess,
  deleteUserFail,
  deleteUserSuccess,
  getLineManagerFail,
  getLineManagerSuccess,
  getUserFail,
  getUserSuccess,
  getUserTargetAndAchievement,
  getUserTargetAndAchievementFail,
  getUserTargetAndAchievementSuccess,
  getUsersFail,
  getUsersInfoFail,
  getUsersInfoSuccess,
  getUsersSuccess,
  sampleExcelFail,
  sampleExcelSuccess,
  updateRouteUserFail,
  updateRouteUserSuccess,
  updateUserFail,
  userPerformanceFail,
  userPerformanceSuccess,
} from "./actions"
// Calender Redux States
import {
  ADD_NEW_USER,
  DELETE_USER,
  DELETE_USER_ROUTE,
  GET_LINE_MANAGER,
  GET_TARGET_AND_ACHIEVEMENT,
  GET_USER,
  GET_USERS,
  SAMPLE_EXCEL_DOWNLOAD,
  UPDATE_SET_ROUTE,
  UPDATE_USER,
  USER_INFO_DATA,
  USER_PERFORMANCE,
} from "./actionTypes"

function* fetchUsers({ payload: { authtoken, orgid, region } }) {
  try {
    //  console.log(orgid)
    delay(500)
    const response = yield call(getUsers, authtoken, orgid, region)
    yield put(getUsersSuccess(response.data.users))

    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(getUsersFail(error))
    //  console.log("hi")
  }
}
function* fetchLineManager({ payload: { authtoken, role } }) {
  try {
    //  console.log(role)
    delay(500)
    const response = yield call(getLineManager, authtoken, role)
    yield put(getLineManagerSuccess(response))

    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(getLineManagerFail(error))
    //  console.log("hi")
  }
}

function* fetchUser({ payload: id, authtoken, region }) {
  try {
    delay(500)
    const response = yield call(getUser, id, authtoken, region)
    yield put(getUserSuccess(response))
  } catch (error) {
    yield put(getUserFail(error))
  }
}
function* fetchUsersPerformance({ payload: { authtoken, orgid } }) {
  try {
    //  console.log(orgid)
    delay(500)
    const response = yield call(getUsersPerformance, authtoken, orgid)
    yield put(userPerformanceSuccess(response))

    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(userPerformanceFail(error))
    //  console.log("hi")
  }
}
function* onAddNewUser({ payload: { data, history, authtoken } }) {
  // let adduserData = data.data
  try {
    const response = yield call(addUser, data, authtoken)

    yield put(addUserSuccess(response))
    toast("🦄 user added successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    history.push("/user-details")
  } catch (error) {
    //  console.log("Hello line number 51")
    if (!error.response) {
      // history.push("/userdata")
    } else {
      let message = error.response.data.message
      // console.log("error message,", error.response.data.message)
      yield put(addUserFail(message))
      toast.error(message)
    }
  }
}

function* onUpdateUser({ payload: { id, data, token, history } }) {
  //  console.log(`file: saga.js ~ line 107 ~ function*onUpdateUser ~ token`, token)
  try {
    const response = yield call(updateUser, id, data, token)
    //  console.log(
    //   `file: saga.js ~ line 109 ~ function*onUpdateUser ~ response`,
    //   response
    // )
    // yield put(updateUserSuccess(response))
    toast("🦄 user updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    if (response.status === "success") {
      history.push("/user-details")
    }
  } catch (error) {
    //  console.log(error.response)
    yield put(updateUserFail(error))
  }
}

function* onUpdateRoute({ payload: { id, data, authtoken, history } }) {
  try {
    //  console.log(history)
    const response = yield call(updateRouteUser, id, data, authtoken)
    //  console.log(response)
    yield put(updateRouteUserSuccess(response.users))
    toast("🦄 Routes updated successfully!", {
      position: "top-right",
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    history.push("/user-details")
  } catch (error) {
    //  console.log(error)

    if (!error.response) {
      history.push("/user-details")
    } else {
      let message = error.response.data.message
      // console.log("error message,", error.response.data.message)
      yield put(updateRouteUserFail(message))
      toast.error(message)
    }
  }
}
function* onDeleteUser({ payload: data }) {
  try {
    const response = yield call(deleteUser, data)
    yield put(deleteUserSuccess(response))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

// function* onDeleteUserRoute({  payload: { authtoken, userId, routeId} }) {
//   try {
//     const response = yield call(deleteUserRoute, authtoken, userId, routeId)
//     yield put(deleteRouteUserSuccess(response))
//   } catch (error) {
//     yield put(deleteRouteUserFail(error))
//   }
// }

function* fetchUsersInfo({
  payload: { authtoken, id, currentPage, value, role, region },
}) {
  try {
    //  console.log(id)
    delay(500)
    const response = yield call(
      getAllUserInfo,
      authtoken,
      id,
      currentPage,
      value,
      role,
      region
    )
    yield put(getUsersInfoSuccess(response))

    //  console.log('response',response);
  } catch (error) {
    yield put(getUsersInfoFail(error))
    //  console.log("hi")
  }
}
function* onDeleteUserRoute({ payload: { authtoken, userId, routeId } }) {
  try {
    const response = yield call(deleteUserRoute, authtoken, userId, routeId)
    //  console.log("hi")
    //  console.log(response)
    yield put(deleteRouteUserSuccess(response))
    toast("🦄 Route deleted successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  } catch (error) {
    if (!error.response) {
      toast("🦄 Route Deleted successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else {
      let message = error.response.data.message
      yield put(deleteRouteUserFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}
function* fetchSampleExcel({ payload: { authtoken, id } }) {
  try {
    //  console.log(authtoken);
    //  console.log(id)
    delay(500)
    const response = yield call(getSampleDataExcel, authtoken, id)
    yield put(sampleExcelSuccess(response))

    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(sampleExcelFail(error))
    //  console.log("hi")
  }
}

function* fetchUserTargetAchievement({ payload: { authtoken, id, date } }) {
  try {
    delay(500)
    const response = yield call(getTargetAndAchievementAPI, authtoken, id, date)
    yield put(getUserTargetAndAchievementSuccess(response))

    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(getUserTargetAndAchievementFail(error?.message))
    //  console.log("hi")
  }
}

function* UserSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER, fetchUser)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(UPDATE_SET_ROUTE, onUpdateRoute)

  yield takeEvery(DELETE_USER, onDeleteUser)
  yield takeEvery(GET_LINE_MANAGER, fetchLineManager)
  yield takeEvery(DELETE_USER_ROUTE, onDeleteUserRoute)
  yield takeEvery(USER_INFO_DATA, fetchUsersInfo)
  yield takeEvery(USER_PERFORMANCE, fetchUsersPerformance)
  yield takeEvery(SAMPLE_EXCEL_DOWNLOAD, fetchSampleExcel)
  yield takeEvery(GET_TARGET_AND_ACHIEVEMENT, fetchUserTargetAchievement)
}

export default UserSaga
