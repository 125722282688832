import { toast } from "react-toastify"
import { call, delay, put, takeEvery } from "redux-saga/effects"
import {
  addConsumerOffer,
  addOffersdata,
  addRetailerOffer,
  getAllOffer,
  getDetailsOffer,
} from "../../helpers/backend_helper.js"
import {
  ADD_CONSUMER_OFFER,
  ADD_OFFER,
  ADD_RETAILER_OFFER,
  GET_OFFERS,
  GET_OFFER_DETAILS,
} from "./actionTypes"
import {
  addConsumerOfferFail,
  addConsumerOfferSuccess,
  addOfferFail,
  addOfferSuccess,
  addRetailerOfferFail,
  addRetailerOfferSuccess,
  getOffersDetailsFail,
  getOffersDetailsSuccess,
  getOffersFail,
  getOffersSuccess,
} from "./actions"

function* onAddOffer({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(addOffersdata, data, authtoken)

    //  console.log(data);
    //  console.log(response);

    if (response.status === "success") {
      //  console.log('test on 18', );
      yield put(addOfferSuccess(response))
      toast.success("Offer Added Successfully")
    }
    history.push("/details-offer")
  } catch (error) {
    if (!error.response) {
      //  console.log('line 20');
      history.push("/details-offer")
    } else {
      let message = error.response.data.message
      //  console.log('line 24');
      // console.log("error message,", error.response.data.message)
      yield put(addOfferFail(message))
      toast.error(message)
    }
  }
}

function* onAddConsumerOffer({ payload: { data, history, authtoken, id } }) {
  try {
    //  console.log(data , authtoken, id);
    const response = yield call(addConsumerOffer, data, authtoken, id)

    //  console.log(data);
    //  console.log(response);

    if (response.status === "success") {
      //  console.log('test on 18', );
      yield put(addConsumerOfferSuccess(response))
      toast.success("success")
      history.push("/details-offer")
    }
  } catch (error) {
    if (!error.response) {
      //  console.log('line 20');
      history.push("/details-offer")
    } else {
      let message = error.response.data.message
      //  console.log('line 24');
      // console.log("error message,", error.response.data.message)
      yield put(addConsumerOfferFail(message))
      toast.error(message)
    }
  }
}
function* onRetailerOffer({ payload: { data, history, authtoken, id } }) {
  try {
    //  console.log(data , authtoken, id);
    const response = yield call(addRetailerOffer, data, authtoken, id)

    //  console.log(data);
    //  console.log(response);

    if (response.status === "success") {
      //  console.log('test on 18', );
      yield put(addRetailerOfferSuccess(response))
      toast.success("success")
      history.push("/details-offer")
    }
  } catch (error) {
    if (!error.response) {
      //  console.log('line 20');
      history.push("/details-offer")
    } else {
      let message = error.response.data.message
      //  console.log('line 24');
      // console.log("error message,", error.response.data.message)
      yield put(addRetailerOfferFail(message))
      toast.error(message)
    }
  }
}

function* fetchOffers({ payload: { authtoken } }) {
  try {
    delay(500)
    const response = yield call(getAllOffer, authtoken)
    //  console.log(response);
    yield put(getOffersSuccess(response))
    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(getOffersFail(error))
    //  console.log('hi');
  }
}

function* fetchDetailsOffer({ payload: { authtoken, id } }) {
  try {
    delay(500)
    const response = yield call(getDetailsOffer, authtoken, id)
    //  console.log(response);
    yield put(getOffersDetailsSuccess(response))
  } catch (error) {
    yield put(getOffersDetailsFail(error))
    //  console.log('hi');
  }
}

function* OfferSaga() {
  yield takeEvery(GET_OFFERS, fetchOffers)
  yield takeEvery(ADD_OFFER, onAddOffer)
  yield takeEvery(ADD_CONSUMER_OFFER, onAddConsumerOffer)
  yield takeEvery(ADD_RETAILER_OFFER, onRetailerOffer)
  yield takeEvery(GET_OFFER_DETAILS, fetchDetailsOffer)
}

export default OfferSaga
