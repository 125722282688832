export const GET_NEW_HISTORY = "GET_NEW_HISTORY"
export const GET_NEW_HISTORY_SUCCESS = "GET_NEW_HISTORY_SUCCESS"
export const GET_NEW_HISTORY_FAIL = "GET_NEW_HISTORY_FAIL"

export const GET_TODAY_ACTIVITY = "GET_TODAY_ACTIVITY"
export const GET_TODAY_ACTIVITY_SUCCESS = "GET_TODAY_ACTIVITY_SUCCESS"
export const GET_TODAY_ACTIVITY_FAIL = "GET_TODAY_ACTIVITY_FAIL"

export const GET_DP_ACTIVITY = "GET_DP_ACTIVITY"
export const GET_DP_ACTIVITY_SUCCESS = "GET_DP_ACTIVITY_SUCCESS"
export const GET_DP_ACTIVITY_FAIL = "GET_DP_ACTIVITY_FAIL"


export const GET_DP_ORDER_ACTIVITY = "GET_DP_ORDER_ACTIVITY"
export const GET_DP_ORDER_ACTIVITY_SUCCESS = "GET_DP_ORDER_ACTIVITY_SUCCESS"
export const GET_DP_ORDER_ACTIVITY_FAIL = "GET_DP_ORDER_ACTIVITY_FAIL"

export const UPDATE_ORDER = "UPDATE_ORDER"
export const UPDATE_RECONCILIATION = "UPDATE_RECONCILIATION"


export const ADD_DP_DAY_OPENING = "ADD_DP_DAY_OPENING"
export const ADD_DP_DAY_OPENING_SUCCESS = "ADD_DP_DAY_OPENING_SUCCESS"
export const ADD_DP_DAY_OPENING_FAIL = "ADD_DP_DAY_OPENING_FAIL"

export const ADD_DP_RECONCILIATION = "ADD_DP_RECONCILIATION"
export const ADD_DP_RECONCILIATION_SUCCESS = "ADD_DP_RECONCILIATION_SUCCESS"
export const ADD_DP_RECONCILIATION_FAIL = "ADD_DP_RECONCILIATION_FAIL"


export const GET_DP_DAY_OPENING = "GET_DP_DAY_OPENING"
export const GET_DP_DAY_OPENING_SUCCESS = "GET_DP_DAY_OPENING_SUCCESS"
export const GET_DP_DAY_OPENING_FAIL = "GET_DP_DAY_OPENING_FAIL"

export const GET_DP_RECONCILIATION = "GET_DP_RECONCILIATION"
export const GET_DP_RECONCILIATION_SUCCESS = "GET_DP_RECONCILIATION_SUCCESS"
export const GET_DP_RECONCILIATION_FAIL = "GET_DP_RECONCILIATION_FAIL"

export const GET_DP_DAY_OPENING_CONFIRM = "GET_DP_DAY_OPENING_CONFIRM"
export const GET_DP_DAY_OPENING_CONFIRM_SUCCESS = "GET_DP_DAY_OPENING_CONFIRM_SUCCESS"
export const GET_DP_DAY_OPENING_CONFIRM_FAIL = "GET_DP_DAY_OPENING_CONFIRM_FAIL"


export const GET_RECONCILIATION_CONFIRM = "GET_RECONCILIATION_CONFIRM"
export const GET_RECONCILIATION_CONFIRM_SUCCESS = "GET_RECONCILIATION_CONFIRM_SUCCESS"
export const GET_RECONCILIATION_CONFIRM_FAIL = "GET_RECONCILIATION_CONFIRM_FAIL"






