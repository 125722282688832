import { ADD_TARGET_FAIL, ADD_TARGET_SUCCESS, GET_ALL_TARGET_FAIL, GET_ALL_TARGET_SUCCESS,
  UPDATE_TARGET_SUCCESS,
  UPDATE_TARGET_FAIL } from "./actionTypes"
  
  const INIT_STATE = {
      
      targets: [],
      target:{},
      error: {},
      loading: true,
  
    }
  
    const TargetReducer = (state = INIT_STATE, action) => {
      switch (action.type) {
        case ADD_TARGET_SUCCESS:
              return {
                ...state,
                loading: false,
                targets: [...state.targets, action.payload],    
              }
        
        case ADD_TARGET_FAIL:
              return {
                ...state,
                loading: true,
                error: action.payload,
              }
        case GET_ALL_TARGET_SUCCESS:
          return {
            ...state,
            targets: action.payload,
            loading: false,
          }
        case GET_ALL_TARGET_FAIL:
          return {
            ...state,
            loading: true,
            error: action.payload,
          }
          case UPDATE_TARGET_SUCCESS:
        return {
          ...state,
          targets: state.targets.map(target =>
            target.id.toString() === action.payload.id.toString()
              ? { target, ...action.payload }
              : target
          ),
        }
  
      case UPDATE_TARGET_FAIL:
        return {
          ...state,
          error: action.payload,
        }
        default:
          return state
      }
    }
    
  export default TargetReducer