import {
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
} from "./actionTypes"

export const updatePassword = (values, history) => {
  return {
    type: UPDATE_PASSWORD,
    payload: { values, history },
  }
}

export const updatePasswordSuccess = message => {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    payload: {
      message,
    },
  }
}

export const updatePasswordFail = message => {
  return {
    type: UPDATE_PASSWORD_FAIL,
    payload: {
      message,
    },
  }
}
