export const ADD_NEW_INVENTORY = "ADD_NEW_INVENTORY"
export const ADD_NEW_INVENTORY_SUCCESS = "ADD_NEW_INVENTORY_SUCCESS"
export const ADD_NEW_INVENTORY_FAIL = "ADD_NEW_INVENTORY_FAIL"


export const GET_NEW_INVENTORY = "GET_NEW_INVENTORY"
export const GET_NEW_INVENTORY_SUCCESS = "GET_NEW_INVENTORY_SUCCESS"
export const GET_NEW_INVENTORY_FAIL = "GET_NEW_INVENTORY_FAIL"

export const ADD_DAMAGE_INVENTORY = "ADD_DAMAGE_INVENTORY"
export const ADD_DAMAGE_INVENTORY_SUCCESS = "ADD_DAMAGE_INVENTORY_SUCCESS"
export const ADD_DAMAGE_INVENTORY_FAIL = "ADD_DAMAGE_INVENTORY_FAIL"

export const GET_DAMAGE_INVENTORY = "GET_DAMAGE_INVENTORY"
export const GET_DAMAGE_INVENTORY_SUCCESS = "GET_DAMAGE_INVENTORY_SUCCESS"
export const GET_DAMAGE_INVENTORY_FAIL = "GET_DAMAGE_INVENTORY_FAIL"

export const GET_DAMAGE_ACTIVITY = "GET_DAMAGE_ACTIVITY"
export const GET_DAMAGE_ACTIVITY_SUCCESS = "GET_DAMAGE_ACTIVITY_SUCCESS"
export const GET_DAMAGE_ACTIVITY_FAIL = "GET_DAMAGE_ACTIVITY_FAIL"

export const ADD_DAMAGE_ACTIVITY = "ADD_DAMAGE_ACTIVITY"
export const ADD_DAMAGE_ACTIVITY_SUCCESS = "ADD_DAMAGE_ACTIVITY_SUCCESS"
export const ADD_DAMAGE_ACTIVITY_FAIL = "ADD_DAMAGE_ACTIVITY_FAIL"


export const GET_DAMAGE_RECONCILIATION = "GET_DAMAGE_RECONCILIATION"
export const GET_DAMAGE_RECONCILIATION_SUCCESS = "GET_DAMAGE_RECONCILIATION_SUCCESS"
export const GET_DAMAGE_RECONCILIATION_FAIL = "GET_DAMAGE_RECONCILIATION_FAIL"

export const ADD_DAMAGE_RECONCILIATION = "ADD_DAMAGE_RECONCILIATION"
export const ADD_DAMAGE_RECONCILIATION_SUCCESS = "ADD_DAMAGE_RECONCILIATION_SUCCESS"
export const ADD_DAMAGE_RECONCILIATION_FAIL = "ADD_DAMAGE_RECONCILIATION_FAIL"

export const STORE_PRODUCT_DATA = "STORE_PRODUCT_DATA"
export const STORE_RECONCILED_DATA = "STORE_RECONCILED_DATA"


