import {
  VERIFY_EMAILS,
  VERIFY_EMAILS_SUCCESS,
  VERIFY_EMAILS_FAIL,
} from "./actionTypes"

export const verifyEmail = (values, history, toast) => {
  return {
    type: VERIFY_EMAILS,
    payload: { values, history, toast },
  }
}

export const verifyEmailSuccess = message => {
  return {
    type: VERIFY_EMAILS_SUCCESS,
    payload: {
      message,
    },
  }
}

export const verifyEmailFail = message => {
  return {
    type: VERIFY_EMAILS_FAIL,
    payload: {
      message,
    },
  }
}
