import { GET_DATE_ORDER_FAIL, GET_DATE_ORDER_SUCCESS, GET_ORDERS_FAIL, GET_ORDERS_SUCCESS, GET_ORDER_FAIL, 
  GET_ORDER_SUCCESS, GET_ROUTE_ORDER_FAIL, GET_ROUTE_ORDER_SUCCESS,GET_INVOICE_FAIL,
  GET_EDIT_ORDER_SUCCESS,GET_EDIT_ORDER_FAIL,GET_DIST_DATE_ORDER_SUCCESS,GET_DIST_DATE_ORDER_FAIL,
  GET_INVOICE_SUCCESS,GET_DIST_SHOP_ORDER_SUCCESS,GET_DIST_SHOP_ORDER_FAIL,
  GET_ALL_ORDER_EXCEL_SUCCESS,GET_ALL_ORDER_EXCEL_FAIL,
  GET_ALL_ORDER_SUCCESS,GET_ALL_ORDER_FAIL,GET_DELIVERY_ORDER_EXCEL_SUCCESS,GET_DELIVERY_ORDER_EXCEL_FAIL } from './actionTypes'
const INIT_STATE = {
  orders: [],
  order: {},
  routeOrders:[],
  routeOrder:{},
  dateOrders:[],
  dateOrder:{},
  invocies:[],
  allOrder:[],
  deliveryExcel:[],
  editedInvoice:[],
  distDateOrder:[],
  distShopOrder:[],
  allOrderExcel: [],
  invoice:{},
  error: {},
  loading: true,
  routeloading: true,
  isLoading: false,
  dateLoading:true,
  invoiceLoading:true,
  orderLoading:false,
  editOrderLoading:true,
  distDateOrderLoading:true,
  distShopOrderLoading:true,
  allOrderLoading : true,
  deliveryExcelLoading: true,
  allOrderExcelLOading: true

}


const OrderReducer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
        orderLoading:true,
        loading: false,
      }
    case GET_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
        orderLoading:false,
        loading: true

      }
      case GET_ALL_ORDER_EXCEL_SUCCESS:
        return {
          ...state,
          allOrderExcel: action.payload,
          allOrderExcelLOading: false,
        }
      case GET_ALL_ORDER_EXCEL_FAIL:
        return {
          ...state,
          allOrderExcelLOading:false,
  
        }
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        loading: false,
        

      }

    case GET_ORDER_FAIL:
      return {
        ...state,
        loading: true,
        error: action.payload,
       

        //change here

      }
      case GET_EDIT_ORDER_SUCCESS:
        return {
          ...state,
          editedInvoice: action.payload,
          editOrderLoading:false,
          
  
        }
  
      case GET_EDIT_ORDER_FAIL:
        return {
          ...state,
          editOrderLoading:true,
          error: action.payload,
         
  
         
  
        }
      case GET_ROUTE_ORDER_SUCCESS:
        return {
          ...state,
          routeloading: false,
          routeOrders: action.payload,
          
        }
      case GET_ROUTE_ORDER_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: true
  
        }
        case GET_DATE_ORDER_SUCCESS:
          return {
            ...state,  
            dateOrders: action.payload,
            dateLoading:false,
            
          }
        case GET_DATE_ORDER_FAIL:
          return {
            ...state,
            error: action.payload,
            dateLoading: true
    
          }
          case GET_INVOICE_SUCCESS:
            return {
              ...state,  
              invocies: action.payload,
              invoiceLoading:false,
              
            }
          case GET_INVOICE_FAIL:
            return {
              ...state,
              error: action.payload,
              invoiceLoading: true
      
            }
            case GET_DIST_DATE_ORDER_SUCCESS:
              return {
                ...state,
                distDateOrder: action.payload,
                distDateOrderLoading: false,
               
                
              }
            case GET_DIST_DATE_ORDER_FAIL:
              return {
                ...state,
                error: action.payload,
                distDateOrderLoading: true
        
              }
              case GET_DIST_SHOP_ORDER_SUCCESS:
                return {
                  ...state,
                  distShopOrder: action.payload,
                  distShopOrderLoading: false,
                 
                  
                }
              case GET_DIST_SHOP_ORDER_FAIL:
                return {
                  ...state,
                  error: action.payload,
                  distShopOrderLoading: true
          
                }
                case GET_ALL_ORDER_SUCCESS:
                  return {
                    ...state,
                    allOrder: action.payload,
                    allOrderLoading: false,
                   
                    
                  }
                case GET_ALL_ORDER_FAIL:
                  return {
                    ...state,
                    error: action.payload,
                    allOrderLoading: true
            
                  }
                  case GET_DELIVERY_ORDER_EXCEL_SUCCESS:
                    return {
                      ...state,
                      deliveryExcel: action.payload,
                      deliveryExcelLoading: false,
                     
                      
                    }
                  case GET_DELIVERY_ORDER_EXCEL_FAIL:
                    return {
                      ...state,
                      error: action.payload,
                      deliveryExcelLoading: true
              
                    }
    default:
      return state
  }
}

export default OrderReducer