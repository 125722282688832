import {



    GET_REQUISITION_RBH,
    GET_REQUISITION_RBH_SUCCESS,
    GET_REQUISITION_RBH_FAIL,
    GET_RBH_DISTRIBUTION,
    GET_RBH_DISTRIBUTION_SUCCESS,
    GET_RBH_DISTRIBUTION_FAIL,
    GET_REQUEST_PRODUCT,
    GET_REQUEST_PRODUCT_SUCCESS,
    GET_REQUEST_PRODUCT_FAIL,
    GET_RBH_ORDER,
    GET_RBH_ORDER_SUCCESS,
    GET_RBH_ORDER_FAIL,
    GET_RBH_SALE,
    GET_RBH_SALE_SUCCESS,
    GET_RBH_SALE_FAIL,
    STOCK_ACCEPT,
    STOCK_ACCEPT_SUCCESS,
    STOCK_ACCEPT_FAIL,
    GET_ORDER_REPORT_RBH,
    GET_ORDER_REPORT_RBH_SUCCESS,
    GET_ORDER_REPORT_RBH_FAIL,
    GET_SALES_REPORT_RBH,
    GET_SALES_REPORT_RBH_SUCCESS,
    GET_SALES_REPORT_RBH_FAIL,
    ADD_STOCK_RBH,
    ADD_STOCK_RBH_SUCCESS,
    ADD_STOCK_RBH_FAIL,

    STORE_RBH_DATA
  } from "./actionTypes"

  export const getOrderRbh = (authtoken, startDate, endDate, limit, currentPage, SOList, region, area, territory, distribution) => ({
    type: GET_RBH_ORDER,
    payload: { authtoken, startDate, endDate, limit, currentPage, SOList, region, area, territory, distribution },
  })
  
  export const getOrderRbhSuccess = (data, authtoken) => ({
    type: GET_RBH_ORDER_SUCCESS,
    payload: { data, authtoken },
  })
  
  export const getOrderRbhFail = error => ({
    type: GET_RBH_ORDER_FAIL,
    payload: error,
  })

  export const getSalesRbh = (authtoken, startDate, endDate, limit, currentPage, SOList, region, area, territory, distribution) => ({
    type: GET_RBH_SALE,
    payload: { authtoken, startDate, endDate, limit, currentPage, SOList, region, area, territory, distribution },
  })
  
  export const getSalesRbhSuccess = (data, authtoken) => ({
    type: GET_RBH_SALE_SUCCESS,
    payload: { data, authtoken },
  })
  
  export const getSalesRbhFail = error => ({
    type: GET_RBH_SALE_FAIL,
    payload: error,
  })

  export const getRequisitionRbh = (authtoken, limit, currentPage, disbritutionID, status, requestNo) => ({
    type: GET_REQUISITION_RBH,
    payload: { authtoken, limit, currentPage, disbritutionID, status, requestNo },
  })
  
  export const getRequisitionRbhSuccess = (data, authtoken) => ({
    type: GET_REQUISITION_RBH_SUCCESS,
    payload: { data, authtoken },
  })
  
  export const getRequisitionRbhFail = error => ({
    type: GET_REQUISITION_RBH_FAIL,
    payload: error,
  })

  export const getRbhDistribution = (authtoken, region) => ({
    type: GET_RBH_DISTRIBUTION,
    payload: { authtoken, region },
  })
  
  export const getRbhDistributionSuccess = (data, authtoken) => ({
    type: GET_RBH_DISTRIBUTION_SUCCESS,
    payload: { data, authtoken },
  })
  
  export const getRbhDistributionFail = error => ({
    type: GET_RBH_DISTRIBUTION_FAIL,
    payload: error,
  })
  export const getRequestProduct = (authtoken, requestNo) => ({
    type: GET_REQUEST_PRODUCT,
    payload: { authtoken, requestNo },
  })
  
  export const getRequestProductSuccess = (data, authtoken) => ({
    type: GET_REQUEST_PRODUCT_SUCCESS,
    payload: { data, authtoken },
  })
  
  export const getRequestProductFail = error => ({
    type: GET_REQUEST_PRODUCT_FAIL,
    payload: error,
  })
  export const stockAccept = (body, reqNO, history, authtoken, distribution) => ({
    type: STOCK_ACCEPT,
    payload: {body, reqNO, history, authtoken, distribution },
  })
  
  export const stockAcceptSuccess = data => ({
    type: STOCK_ACCEPT_SUCCESS,
    payload: data,
  })
  
  export const stockAcceptFail = error => ({
    type: STOCK_ACCEPT_FAIL,
    payload: error,
  })

  export const getOrderReportRbh = (authtoken, region) => ({
    type: GET_ORDER_REPORT_RBH,
    payload: { authtoken, region },
  })
  
  export const getOrderReportRbhSuccess = (data, authtoken) => ({
    type: GET_ORDER_REPORT_RBH_SUCCESS,
    payload: { data, authtoken },
  })
  
  export const getOrderReportRbhFail = error => ({
    type: GET_ORDER_REPORT_RBH_FAIL,
    payload: error,
  })
  export const getSalesReportRbh = (authtoken, region) => ({
    type: GET_SALES_REPORT_RBH,
    payload: { authtoken, region },
  })
  
  export const getSalesReportRbhSuccess = (data, authtoken) => ({
    type: GET_SALES_REPORT_RBH_SUCCESS,
    payload: { data, authtoken },
  })
  
  export const getSalesReportRbhFail = error => ({
    type: GET_SALES_REPORT_RBH_FAIL,
    payload: error,
  })
  export const storeToData = (name, data) => ({
    type: STORE_TO_DATA,
    payload: { name, data },
  })

  export const storeRbhData = (name, data) => ({
    type: STORE_RBH_DATA,
    payload: { name, data },
  })
  export const addRbhStock = (data, history, authtoken) => ({
    type: ADD_STOCK_RBH,
    payload: { data, history, authtoken },
  })
  
  export const addRbhStockSuccess = data => ({
    type: ADD_STOCK_RBH_SUCCESS,
    payload: data,
  })
  
  export const addRbhStockFail = error => ({
    type: ADD_STOCK_RBH_FAIL,
    payload: error,
  })
