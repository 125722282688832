import {
    
    GET_ATTENDANCE_SUCCESS, GET_ATTENDANCE_FAIL,
    GET_ATTENDANCE_EXCEL_SUCCESS,GET_ATTENDANCE_EXCEL_FAIL
   
  } from "./actionTypes"
  
  const INIT_STATE = {
      
      attendance: [],
      attendanceLoading:true,
      attendanceExcel: [],
      attendanceExcelLoading:true,
    
  
    }
  
    const AttendanceReducer = (state = INIT_STATE, action) => {
      switch (action.type) {
   
        case GET_ATTENDANCE_SUCCESS:
          return {
            ...state,
            attendance: action.payload,
            attendanceLoading: false,
          }
        case GET_ATTENDANCE_FAIL:
          return {
            ...state,
            error: action.payload,
            attendanceLoading: true,
            
          }
           
        case GET_ATTENDANCE_EXCEL_SUCCESS:
          return {
            ...state,
            attendanceExcel: action.payload,
            attendanceExcelLoading: false,
          }
        case GET_ATTENDANCE_EXCEL_FAIL:
          return {
            ...state,
            error: action.payload,
            attendanceExcelLoading: true,
            
          }
        default:
          return state
      }
    }
    
  export default AttendanceReducer