import {
    ADD_OFFER,
    ADD_OFFER_SUCCESS,
    ADD_OFFER_FAIL,
    ADD_CONSUMER_OFFER,
    ADD_CONSUMER_OFFER_SUCCESS,
    ADD_CONSUMER_OFFER_FAIL,
    ADD_RETAILER_OFFER, ADD_RETAILER_OFFER_SUCCESS, ADD_RETAILER_OFFER_FAIL,
    GET_OFFERS_SUCCESS,
    GET_OFFERS_FAIL,
    GET_OFFER_SUCCESS,
    GET_OFFER_FAIL,
    GET_OFFER_DETAILS_SUCCESS,
    GET_OFFER_DETAILS_FAIL

} from './actionTypes.js'
const INIT_STATE = {
    offers: [],
    offer: {},
    consumerOffers:[],
    consumerOffer:{},
    retailerOffers:[],
    retailerOffer:{},
    offerDetails:[],
    error: {},
    loading: true,
    offerDetailsLoading:true,
    isLoading: false,

}
const OfferReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_OFFER:
            return {
                ...state,
                error: {},
                isLoading: true,
            }
        case ADD_OFFER_SUCCESS:
            return {
                ...state,
                offers: [...state.offers, action.payload],
                isLoading: false,
            }
        case ADD_OFFER_FAIL:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            }
            case ADD_CONSUMER_OFFER:
                return {
                    ...state,
                    error: {},
                    isLoading: true,
                }
            case ADD_CONSUMER_OFFER_SUCCESS:
                return {
                    ...state,
                    consumerOffers: [...state.consumerOffers, action.payload],
                    isLoading: false,
                }
            case ADD_CONSUMER_OFFER_FAIL:
                return {
                    ...state,
                    error: action.payload,
                    isLoading: false,
                }
                case ADD_RETAILER_OFFER:
                return {
                    ...state,
                    error: {},
                    isLoading: true,
                }
            case ADD_RETAILER_OFFER_SUCCESS:
                return {
                    ...state,
                    retailerOffers: [...state.retailerOffers, action.payload],
                    isLoading: false,
                }
            case ADD_RETAILER_OFFER_FAIL:
                return {
                    ...state,
                    error: action.payload,
                    isLoading: false,
                }
        
        
        case GET_OFFERS_SUCCESS:
                return {
                  ...state,
                  offers: action.payload,
                  loading: false,
                }
        case GET_OFFERS_FAIL:
                return {
                  ...state,    
                  error: action.payload,
                  loading: true
          
                }
        case GET_OFFER_SUCCESS:
                return {
                  ...state,
                  offer: action.payload,
                  loading: false,
                }
          
        case GET_OFFER_FAIL:
                return {
                  ...state,
                  loading: true,
                  error: action.payload,
                  //change here
          
                }
        case GET_OFFER_DETAILS_SUCCESS:
                    return {
                      ...state,
                      offerDetails: action.payload,
                      offerDetailsLoading: false,
                    }
        case GET_OFFER_DETAILS_FAIL:
                    return {
                      ...state,    
                      error: action.payload,
                      offerDetailsLoading: true
              
                    }
            default:
                return state
    }

}
export default OfferReducer;