import React from "react"
// import logoLightSvg from "../../assets/images/Frame.png"

const Loading = () => {
  return (
    <React.Fragment>
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <img src={fieldx} alt="image" height="50" /> */}
        <i
          className="bx bx-loader bx-spin fs-1"
          style={{ color: "#2a3042", fontSize: "30px" }}
        ></i>
      </div>
    </React.Fragment>
  )
}

export default Loading
