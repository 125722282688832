// GET ORDERS
export const GET_ORDERS = "GET_ORDERS "
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS"
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL"

// GET ORDER
export const GET_ORDER = "GET_ORDER"
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS"
export const GET_ORDER_FAIL = "GET_ORDER_FAIL"

export const GET_ALL_ORDER_EXCEL = "GET_ALL_ORDER_EXCEL"
export const GET_ALL_ORDER_EXCEL_SUCCESS = "GET_ALL_ORDER_EXCEL_SUCCESS"
export const GET_ALL_ORDER_EXCEL_FAIL = "GET_ALL_ORDER_EXCEL_FAIL"


export const GET_EDIT_ORDER = "GET_EDIT_ORDER"
export const GET_EDIT_ORDER_SUCCESS = "GET_EDIT_ORDER_SUCCESS"
export const GET_EDIT_ORDER_FAIL = "GET_EDIT_ORDER_FAIL"

// GET ROUTE ORDER

export const GET_ROUTE_ORDER = "GET_ROUTE_ORDER"
export const GET_ROUTE_ORDER_SUCCESS = "GET_ROUTE_ORDER_SUCCESS"
export const GET_ROUTE_ORDER_FAIL = "GET_ROUTE_ORDER_FAIL"

// GET DATE ORDER
export const GET_DATE_ORDER = "GET_DATE_ORDER"
export const GET_DATE_ORDER_SUCCESS = "GET_DATE_ORDER_SUCCESS"
export const GET_DATE_ORDER_FAIL = "GET_DATE_ORDER_FAIL"


export const GET_DELIVERY_ORDER_EXCEL = "GET_DELIVERY_ORDER_EXCEL"
export const GET_DELIVERY_ORDER_EXCEL_SUCCESS = "GET_DELIVERY_ORDER_EXCEL_SUCCESS"
export const GET_DELIVERY_ORDER_EXCEL_FAIL = "GET_DELIVERY_ORDER_EXCEL_FAIL"

export const GET_ALL_ORDER = "GET_ALL_ORDER"
export const GET_ALL_ORDER_SUCCESS = "GET_ALL_ORDER_SUCCESS"
export const GET_ALL_ORDER_FAIL = "GET_ALL_ORDER_FAIL"




// GET DIST DATE ORDER

export const GET_DIST_DATE_ORDER = "GET_DIST_DATE_ORDER"
export const GET_DIST_DATE_ORDER_SUCCESS = "GET_DIST_DATE_ORDER_SUCCESS"
export const GET_DIST_DATE_ORDER_FAIL = "GET_DIST_DATE_ORDER_FAIL"

// GET DIST SHOP ORDER
export const GET_DIST_SHOP_ORDER = "GET_DIST_SHOP_ORDER"
export const GET_DIST_SHOP_ORDER_SUCCESS = "GET_DIST_SHOP_ORDER_SUCCESS"
export const GET_DIST_SHOP_ORDER_FAIL = "GET_DIST_SHOP_ORDER_FAIL"

// INVOICE

export const GET_INVOICE = "GET_INVOICE"
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS"
export const GET_INVOICE_FAIL = "GET_INVOICE_FAIL"

