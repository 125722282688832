import { toast } from "react-toastify"
import { call, delay, put, takeEvery } from "redux-saga/effects"
import {
  SobyRBHList,
  SobyToList,
  addStockIn,
  dayCloseData,
  deliverablesData,
  getToOrderList,
  getToSaleList,
  inventoryData,
  ocReportData,
  orderReportData,
  requisitionData,
  salesReportData,
  singleOrderData,
  stockReportData,
  stockReportDetailsData,
  toDistributionList,
  toProductList,
  updateStockInfo,
} from "../../helpers/backend_helper"
import {
  addToStockFail,
  addToStockSuccess,
  doDayCloseFail,
  doDayCloseSuccess,
  getInventoryFail,
  getInventorySuccess,
  getOcReportFail,
  getOcReportSuccess,
  getOrderReportFail,
  getOrderReportSuccess,
  getOrderTOFail,
  getOrderTOSuccess,
  getRequisitionFail,
  getRequisitionSuccess,
  getSOByRBHFail,
  getSOByRBHSuccess,
  getSOByTOFail,
  getSOByTOSuccess,
  getSaleTOFail,
  getSaleTOSuccess,
  getSalesReportFail,
  getSalesReportSuccess,
  getSingleOrderFail,
  getSingleOrderSuccess,
  getStockReportFail,
  getStockReportSuccess,
  getToDeliverablesFail,
  getToDeliverablesSuccess,
  getToDistribution,
  getToDistributionFail,
  getToDistributionSuccess,
  getToProductFail,
  getToProductSuccess,
  stockReportDetailsFail,
  stockReportDetailsSuccess,
  storeToData,
  updateStockFail,
  updateStockSuccess,
} from "./action.js"
import {
  ADD_STOCK_TO,
  DO_DAY_CLOSE,
  GET_DAY_OC_REPORT,
  GET_INVENTORY,
  GET_ORDER_REPORT,
  GET_REQUISITION,
  GET_SALES_REPORT,
  GET_SINGLE_ORDER,
  GET_SO_BY_RBH,
  GET_SO_BY_TO,
  GET_STOCK_REPORT,
  GET_TO_DELIVERABLES,
  GET_TO_DISTRIBUTION,
  GET_TO_ORDER,
  GET_TO_PRODUCT,
  GET_TO_SALE,
  STOCK_REPORT_DETAILS,
  UPDATE_STOCK,
} from "./actionTypes"

function* fetchOrderTo({
  payload: {
    authtoken,
    startDate,
    endDate,
    limit,
    currentPage,
    SOList,
    area,
    territory,
    distribution,
  },
}) {
  try {
    const response = yield call(
      getToOrderList,
      authtoken,
      startDate,
      endDate,
      limit,
      currentPage,
      SOList,
      area,
      territory,
      distribution
    )
    //  console.log("cv", response)
    yield put(getOrderTOSuccess(response))
  } catch (error) {
    yield put(getOrderTOFail(error))
    //  console.log("hi")
  }
}
function* fetchSaleTo({
  payload: {
    authtoken,
    startDate,
    endDate,
    limit,
    currentPage,
    SOList,
    area,
    territory,
    distribution,
  },
}) {
  try {
    delay(500)
    const response = yield call(
      getToSaleList,
      authtoken,
      startDate,
      endDate,
      limit,
      currentPage,
      SOList,
      area,
      territory,
      distribution
    )
    yield put(getSaleTOSuccess(response))
  } catch (error) {
    yield put(getSaleTOFail(error))
    //  console.log("hi")
  }
}
function* fetchToDistribution({ payload: { authtoken, area } }) {
  try {
    delay(500)
    const response = yield call(toDistributionList, authtoken, area)
    yield put(getToDistributionSuccess(response))
  } catch (error) {
    yield put(getToDistributionFail(error))
    //  console.log("hi")
  }
}
function* fetchSoByTo({ payload: { authtoken, toID } }) {
  try {
    const response = yield call(SobyToList, authtoken, toID)
    yield put(getSOByTOSuccess(response))
  } catch (error) {
    yield put(getSOByTOFail(error))
    //  console.log("hi")
  }
}
function* fetchSoByRBH({ payload: { authtoken, toID } }) {
  try {
    const response = yield call(SobyRBHList, authtoken, toID)
    yield put(getSOByRBHSuccess(response))
  } catch (error) {
    yield put(getSOByRBHFail(error))
    //  console.log("hi")
  }
}
function* fetchProducts({ payload: { authtoken, id } }) {
  try {
    const response = yield call(toProductList, authtoken, id)
    //  console.log("response", response)
    response?.products?.forEach((product, index) => {
      product.stock = 0
    })
    yield put(getToProductSuccess(response))
    //  console.log("response?.product", response?.product)
    yield put(storeToData("updatedToProduct", response?.products))
  } catch (error) {
    yield put(getToProductFail(error))
    //  console.log("hi")
  }
}

function* fetchInventoryList({ payload: { authtoken, disbritutionID } }) {
  try {
    const response = yield call(inventoryData, authtoken, disbritutionID)
    yield put(getInventorySuccess(response))
  } catch (error) {
    yield put(getInventoryFail(error))
    //  console.log("hi")
  }
}

function* addStock({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(addStockIn, data, authtoken)
    yield put(addToStockSuccess(response))
    yield put(storeToData("adding", false))
    toast("🦄 Stock In successfully requested!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    history.push("/requisitionInfo")
  } catch (error) {
    if (!error.response) {
      toast("🦄 Stock In successfully requested!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      history.push("/requisitionInfo")
    } else {
      let message = error.response.data.message
      yield put(addToStockFail(message))
      yield put(storeToData("adding", false))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}

function* fetchRequisitionTo({
  payload: { authtoken, limit, currentPage, disbritutionID, status, requestNo },
}) {
  try {
    const response = yield call(
      requisitionData,
      authtoken,
      limit,
      currentPage,
      disbritutionID,
      status,
      requestNo
    )
    yield put(getRequisitionSuccess(response))
  } catch (error) {
    yield put(getRequisitionFail(error))
    //  console.log("hi")
  }
}
function* fetchStockReport({
  payload: {
    authtoken,
    limit,
    currentPage,
    disbritutionID,
    fromDate,
    toDate,
    product,
  },
}) {
  try {
    const response = yield call(
      stockReportData,
      authtoken,
      limit,
      currentPage,
      disbritutionID,
      fromDate,
      toDate,
      product
    )
    yield put(getStockReportSuccess(response))
  } catch (error) {
    yield put(getStockReportFail(error))
    //  console.log("hi")
  }
}
function* fetchOrderReport({ payload: { authtoken, area } }) {
  try {
    const response = yield call(orderReportData, authtoken, area)
    yield put(getOrderReportSuccess(response))
  } catch (error) {
    yield put(getOrderReportFail(error))
    //  console.log("hi")
  }
}
function* fetchSalesReport({ payload: { authtoken, area } }) {
  try {
    const response = yield call(salesReportData, authtoken, area)
    yield put(getSalesReportSuccess(response))
  } catch (error) {
    yield put(getSalesReportFail(error))
    //  console.log("hi")
  }
}
function* fetchSingleOrder({ payload: { authtoken, orderId } }) {
  try {
    const response = yield call(singleOrderData, authtoken, orderId)
    yield put(getSingleOrderSuccess(response))
  } catch (error) {
    yield put(getSingleOrderFail(error))
    //  console.log("hi")
  }
}
function* fetchOcReport({
  payload: { authtoken, office, date, limit, pageNo },
}) {
  try {
    const response = yield call(
      ocReportData,
      authtoken,
      office,
      date,
      limit,
      pageNo
    )
    yield put(getOcReportSuccess(response))
  } catch (error) {
    yield put(getOcReportFail(error))
    //  console.log("hi")
  }
}

function* dayClose({ payload: { authtoken, office, area } }) {
  try {
    const response = yield call(dayCloseData, authtoken, office)
    yield put(doDayCloseSuccess(response))
    yield put(getToDistribution(authtoken, area))
  } catch (error) {
    yield put(doDayCloseFail(error))
    //  console.log("hi")
  }
}
function* deliverablesInfo({ payload: { authtoken, office } }) {
  try {
    const response = yield call(deliverablesData, authtoken, office)
    yield put(getToDeliverablesSuccess(response))
  } catch (error) {
    yield put(getToDeliverablesFail(error))
    //  console.log("hi")
  }
}

function* stockDetails({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(stockReportDetailsData, data, authtoken)
    //  console.log("hi", data)
    //  console.log(response)
    yield put(stockReportDetailsSuccess(response))
  } catch (error) {
    if (!error.response) {
      // history.push("/requisitionTo")
    } else {
      let message = error.response.data.message
      yield put(stockReportDetailsFail(message))

      toast.error(message)
    }
  }
}

function* updateStockData({
  payload: { data, history, authtoken, distributionId },
}) {
  try {
    const response = yield call(updateStockInfo, data, authtoken)
    //  console.log("hi")
    //  console.log(response)
    yield put(updateStockSuccess(response))
    yield put(storeToData("adding", false))
    toast("🦄 Update Stock successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    history.push(`/RequisitionInfo/${distributionId}`)
  } catch (error) {
    if (!error.response) {
      toast("🦄 Update Stock successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      history.push(`/RequisitionInfo/${distributionId}`)
    } else {
      let message = error.response.data.message
      yield put(updateStockFail(message))
      yield put(storeToData("adding", false))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}
function* TOSaga() {
  yield takeEvery(GET_TO_ORDER, fetchOrderTo)
  yield takeEvery(GET_TO_SALE, fetchSaleTo)
  yield takeEvery(GET_SO_BY_TO, fetchSoByTo)
  yield takeEvery(GET_SO_BY_RBH, fetchSoByRBH)
  yield takeEvery(GET_TO_DISTRIBUTION, fetchToDistribution)
  yield takeEvery(GET_TO_PRODUCT, fetchProducts)
  yield takeEvery(GET_INVENTORY, fetchInventoryList)
  yield takeEvery(ADD_STOCK_TO, addStock)
  yield takeEvery(GET_REQUISITION, fetchRequisitionTo)
  yield takeEvery(GET_STOCK_REPORT, fetchStockReport)
  yield takeEvery(GET_ORDER_REPORT, fetchOrderReport)
  yield takeEvery(GET_SALES_REPORT, fetchSalesReport)
  yield takeEvery(GET_SINGLE_ORDER, fetchSingleOrder)
  yield takeEvery(GET_DAY_OC_REPORT, fetchOcReport)
  yield takeEvery(DO_DAY_CLOSE, dayClose)
  yield takeEvery(GET_TO_DELIVERABLES, deliverablesInfo)
  yield takeEvery(STOCK_REPORT_DETAILS, stockDetails)
  yield takeEvery(UPDATE_STOCK, updateStockData)
}

export default TOSaga
