import { toast } from "react-toastify"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
//axios
import { axiosForgetPassword } from "helpers/api_helper"

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes"
import { userForgetPasswordError, userForgetPasswordSuccess } from "./actions"

//
const asyncForgetPassword = async email => {
  try {
    const response = await axiosForgetPassword(email)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    const { email } = user
    const response = yield call(asyncForgetPassword, email)
    // toast.success("hello ami wokring")
    //  console.log("heelo", response)
    if (response.status == "success") {
      toast.success(response.message)
      yield put(userForgetPasswordSuccess(response.message))
      // history.push("/email-verify-forget-pass")
    } else {
      toast.error(response.message)
      yield put(userForgetPasswordError(response.message))
    }
  } catch (error) {}
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
