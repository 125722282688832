import { call, put, takeEvery } from "redux-saga/effects"
import {
  getAllDateOrders,
  getAllOrderExcel,
  getAllOrderexcel,
  getAllOrders,
  getAllRouteOrders,
  getDeliveryOrderExcel,
  getDistDateOrders,
  getDistShopOrders,
  getEditedOrder,
  getInvoice,
} from "../../helpers/backend_helper"
import {
  GET_ALL_ORDER,
  GET_ALL_ORDER_EXCEL,
  GET_DATE_ORDER,
  GET_DELIVERY_ORDER_EXCEL,
  GET_DIST_DATE_ORDER,
  GET_DIST_SHOP_ORDER,
  GET_EDIT_ORDER,
  GET_INVOICE,
  GET_ORDERS,
  GET_ROUTE_ORDER,
} from "./actionTypes"
import {
  getAllOrderExcelFail,
  getAllOrderExcelSuccess,
  getDateOrderFail,
  getDateOrderSuccess,
  getDeliveryOrderExcelFail,
  getDeliveryOrderExcelSuccess,
  getDistDateOrderFail,
  getDistDateOrderSuccess,
  getDistShopOrdersFail,
  getDistShopOrdersSuccess,
  getEditOrderFail,
  getEditOrderSuccess,
  getInvoiceFail,
  getInvoiceSuccess,
  getOrdersFail,
  getOrdersSuccess,
  getRouteOrderFail,
  getRouteOrderSuccess,
  getallOrderFail,
  getallOrderSuccess,
} from "./actions"
function* fetchOrders({ payload: { authtoken, id } }) {
  try {
    const response = yield call(getAllOrders, authtoken, id)
    //  console.log(response);
    yield put(getOrdersSuccess(response))
  } catch (error) {
    yield put(getOrdersFail(error))
    //  console.log('hi');
  }
}
function* fetchRangeOrderExcel({
  payload: { authtoken, fromDate, toDate, pageNo, limit, region },
}) {
  try {
    const response = yield call(
      getAllOrderExcel,
      authtoken,
      fromDate,
      toDate,
      pageNo,
      limit,
      region
    )
    //  console.log(response);
    yield put(getAllOrderExcelSuccess(response))
  } catch (error) {
    yield put(getAllOrderExcelFail(error))
    //  console.log('hi');
  }
}
function* fetchRouteOrders({ payload: { authtoken, orgid, route, month } }) {
  try {
    const response = yield call(
      getAllRouteOrders,
      authtoken,
      orgid,
      route,
      month
    )
    //  console.log(response);
    yield put(getRouteOrderSuccess(response))
  } catch (error) {
    yield put(getRouteOrderFail(error))
    //  console.log('hi');
  }
}

function* fetchDateOrders({ payload: { authtoken, orgid, date, routeID } }) {
  try {
    //  console.log("test saga", authtoken, orgid, date, routeID);
    // console.log(orgid);
    // console.log(routeID);
    // console.log(date);
    const response = yield call(
      getAllDateOrders,
      authtoken,
      orgid,
      date,
      routeID
    )
    //  console.log(response);
    yield put(getDateOrderSuccess(response))
  } catch (error) {
    //  console.log('hi');
    yield put(getDateOrderFail(error))
  }
}

function* fetchDistDateOrders({
  payload: { authtoken, orgid, officeId, month },
}) {
  try {
    //  console.log(authtoken);
    //  console.log(orgid);
    //  console.log(officeId);
    //  console.log(month);
    const response = yield call(
      getDistDateOrders,
      authtoken,
      orgid,
      officeId,
      month
    )
    //  console.log(response);
    yield put(getDistDateOrderSuccess(response))
  } catch (error) {
    yield put(getDistDateOrderFail(error))
    //  console.log('hi');
  }
}
function* fetchDistShopOrders({
  payload: { authtoken, orgid, officeId, month },
}) {
  try {
    //  console.log(authtoken);
    //  console.log(orgid);
    //  console.log(officeId);
    //  console.log(month);
    const response = yield call(
      getDistShopOrders,
      authtoken,
      orgid,
      officeId,
      month
    )
    //  console.log(response);
    yield put(getDistShopOrdersSuccess(response))
  } catch (error) {
    yield put(getDistShopOrdersFail(error))
    //  console.log('hi');
  }
}

function* fetchInvoices({ payload: { authtoken, id } }) {
  try {
    const response = yield call(getInvoice, authtoken, id)
    //  console.log(response);
    yield put(getInvoiceSuccess(response))
  } catch (error) {
    //  console.log('hi');
    yield put(getInvoiceFail(error))
  }
}
function* fetchEditedOrder({ payload: { authtoken, id } }) {
  try {
    //  console.log(authtoken);
    //  console.log(id);
    const response = yield call(getEditedOrder, authtoken, id)
    //  console.log(response);
    yield put(getEditOrderSuccess(response))
  } catch (error) {
    //  console.log('hi');
    yield put(getEditOrderFail(error))
  }
}
function* fetchAllOrderExcel({ payload: { authtoken, currentDate } }) {
  try {
    //  console.log('authtoken', authtoken);
    //  console.log('currentDate', currentDate);
    const response = yield call(getAllOrderexcel, authtoken, currentDate)
    //  console.log(response);
    yield put(getallOrderSuccess(response))
  } catch (error) {
    //  console.log('hi');
    yield put(getallOrderFail(error))
  }
}
function* fetchDeliveryOrderExcel({ payload: { authtoken, currentDate } }) {
  //  console.log("currentDate", currentDate);
  try {
    const response = yield call(getDeliveryOrderExcel, authtoken, currentDate)
    //  console.log(response);
    yield put(getDeliveryOrderExcelSuccess(response))
  } catch (error) {
    //  console.log('hi');
    yield put(getDeliveryOrderExcelFail(error))
  }
}

function* OrderSaga() {
  yield takeEvery(GET_ORDERS, fetchOrders)
  yield takeEvery(GET_ROUTE_ORDER, fetchRouteOrders)
  yield takeEvery(GET_DATE_ORDER, fetchDateOrders)
  yield takeEvery(GET_INVOICE, fetchInvoices)
  yield takeEvery(GET_EDIT_ORDER, fetchEditedOrder)
  yield takeEvery(GET_DIST_DATE_ORDER, fetchDistDateOrders)
  yield takeEvery(GET_DIST_SHOP_ORDER, fetchDistShopOrders)
  yield takeEvery(GET_ALL_ORDER, fetchAllOrderExcel)
  yield takeEvery(GET_DELIVERY_ORDER_EXCEL, fetchDeliveryOrderExcel)
  yield takeEvery(GET_ALL_ORDER_EXCEL, fetchRangeOrderExcel)
}
export default OrderSaga
