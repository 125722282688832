import { toast } from "react-toastify"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  addStockInRbh,
  confirmStockAccept,
  getRbhOrderList,
  getRbhSalesList,
  rbhDistributionList,
  rbhOrderReportData,
  rbhRequisition,
  rbhSalesReportData,
  requestProductList,
} from "../../helpers/backend_helper"
import {
  addRbhStockFail,
  addRbhStockSuccess,
  getOrderRbhFail,
  getOrderRbhSuccess,
  getOrderReportRbhFail,
  getOrderReportRbhSuccess,
  getRbhDistributionFail,
  getRbhDistributionSuccess,
  getRequestProductFail,
  getRequestProductSuccess,
  getRequisitionRbh,
  getRequisitionRbhFail,
  getRequisitionRbhSuccess,
  getSalesRbhFail,
  getSalesRbhSuccess,
  getSalesReportRbhFail,
  getSalesReportRbhSuccess,
  stockAcceptFail,
  stockAcceptSuccess,
  storeRbhData,
} from "./action.js"
import {
  ADD_STOCK_RBH,
  GET_ORDER_REPORT_RBH,
  GET_RBH_DISTRIBUTION,
  GET_RBH_ORDER,
  GET_RBH_SALE,
  GET_REQUEST_PRODUCT,
  GET_REQUISITION_RBH,
  GET_SALES_REPORT_RBH,
  STOCK_ACCEPT,
} from "./actionTypes"

function* fetchOrderRbh({
  payload: {
    authtoken,
    startDate,
    endDate,
    limit,
    currentPage,
    SOList,
    region,
    area,
    territory,
    distribution,
  },
}) {
  try {
    const response = yield call(
      getRbhOrderList,
      authtoken,
      startDate,
      endDate,
      limit,
      currentPage,
      SOList,
      region,
      area,
      territory,
      distribution
    )
    //  console.log('cv', response);
    yield put(getOrderRbhSuccess(response))
  } catch (error) {
    yield put(getOrderRbhFail(error))
    //  console.log("hi")
  }
}

function* fetchSaleRbh({
  payload: {
    authtoken,
    startDate,
    endDate,
    limit,
    currentPage,
    SOList,
    region,
    area,
    territory,
    distribution,
  },
}) {
  try {
    const response = yield call(
      getRbhSalesList,
      authtoken,
      startDate,
      endDate,
      limit,
      currentPage,
      SOList,
      region,
      area,
      territory,
      distribution
    )
    //  console.log('cv', response);
    yield put(getSalesRbhSuccess(response))
  } catch (error) {
    yield put(getSalesRbhFail(error))
    //  console.log("hi")
  }
}

function* fetchRequisitionRbh({
  payload: { authtoken, limit, currentPage, disbritutionID, status, requestNo },
}) {
  try {
    const response = yield call(
      rbhRequisition,
      authtoken,
      limit,
      currentPage,
      disbritutionID,
      status,
      requestNo
    )
    yield put(getRequisitionRbhSuccess(response))
  } catch (error) {
    yield put(getRequisitionRbhFail(error))
    //  console.log("hi")
  }
}

function* fetchRbhDistribution({ payload: { authtoken, region } }) {
  try {
    const response = yield call(rbhDistributionList, authtoken, region)
    yield put(getRbhDistributionSuccess(response))
  } catch (error) {
    yield put(getRbhDistributionFail(error))
    //  console.log("hi")
  }
}

function* fetchRequestProduct({ payload: { authtoken, requestNo } }) {
  try {
    const response = yield call(requestProductList, authtoken, requestNo)
    //  console.log('response', response);
    yield put(getRequestProductSuccess(response))
    //  console.log('response?.inventoryrequest', response?.inventoryrequest);
    yield put(storeRbhData("updatedRequestProduct", response?.inventoryrequest))
  } catch (error) {
    yield put(getRequestProductFail(error))
    //  console.log("hi")
  }
}
function* stockAcceptData({
  payload: { body, reqNO, history, authtoken, distribution },
}) {
  //  console.log("distribution",distribution);
  //  console.log('data', reqNO);
  try {
    const response = yield call(confirmStockAccept, body, reqNO, authtoken)
    yield put(stockAcceptSuccess(response))
    yield put(storeRbhData("adding", false))
    yield put(getRequisitionRbh(authtoken, 20, 1, distribution, "", ""))
    toast("🦄 Stock Verified successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    history.push(`/rbh-requisition/${distribution}`)
  } catch (error) {
    if (!error.response) {
      toast("🦄 Stock Verified successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      history.push(`/rbh-requisition/${distribution}`)
    } else {
      let message = error.response.data.message
      yield put(stockAcceptFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}

function* fetchOrderReportRbh({ payload: { authtoken, region } }) {
  try {
    const response = yield call(rbhOrderReportData, authtoken, region)
    yield put(getOrderReportRbhSuccess(response))
  } catch (error) {
    yield put(getOrderReportRbhFail(error))
    //  console.log("hi")
  }
}
function* fetchSalesReportRbh({ payload: { authtoken, region } }) {
  try {
    const response = yield call(rbhSalesReportData, authtoken, region)
    yield put(getSalesReportRbhSuccess(response))
  } catch (error) {
    yield put(getSalesReportRbhFail(error))
    //  console.log("hi")
  }
}
function* addStockRbh({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(addStockInRbh, data, authtoken)
    yield put(addRbhStockSuccess(response))
    yield put(storeRbhData("stockAddLoading", false))
    toast("🦄 Stock In successfully requested!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    history.push("/rbh-requisition-info")
  } catch (error) {
    if (!error.response) {
      toast("🦄 Stock In successfully requested!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      history.push("/rbh-requisition-info")
    } else {
      let message = error.response.data.message
      yield put(addRbhStockFail(message))
      yield put(storeRbhData("stockAddLoading", false))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}

function* RbhSaga() {
  yield takeEvery(GET_REQUISITION_RBH, fetchRequisitionRbh)
  yield takeEvery(GET_RBH_DISTRIBUTION, fetchRbhDistribution)
  yield takeEvery(GET_REQUEST_PRODUCT, fetchRequestProduct)
  yield takeEvery(GET_RBH_ORDER, fetchOrderRbh)
  yield takeEvery(STOCK_ACCEPT, stockAcceptData)
  yield takeEvery(GET_RBH_SALE, fetchSaleRbh)
  yield takeEvery(GET_ORDER_REPORT_RBH, fetchOrderReportRbh)
  yield takeEvery(GET_SALES_REPORT_RBH, fetchSalesReportRbh)
  yield takeEvery(ADD_STOCK_RBH, addStockRbh)
}

export default RbhSaga
