import {
  ADD_BRAND_FAIL,
  ADD_BRAND_SUCCESS,
  ADD_NEW_BRAND,
  GET_BRANDS,
  GET_BRANDS_FAIL,
  GET_BRANDS_SUCCESS,
  GET_BRANDS_STATUS,
  GET_BRANDS_STATUS_SUCCESS,
  GET_BRANDS_STATUS_FAIL
} from "./actionTypes"
  
  export const addNewBrand = (data, history,authtoken) => ({
    type: ADD_NEW_BRAND,
    payload: { data, history ,authtoken},
  })
  
  export const addBrandSuccess = data => ({
    type: ADD_BRAND_SUCCESS,
    payload: {data},
  })
  
  export const addBrandFail = error => ({
    type: ADD_BRAND_FAIL,
    payload: error,
  })

export const getBrands = (authtoken, orgid) => ({
  type: GET_BRANDS,
  payload: { authtoken, orgid }
})

export const getBrandsSuccess = data => ({
  type: GET_BRANDS_SUCCESS,
  payload: {data},
})

export const getBrandsFail = error => ({
  type: GET_BRANDS_FAIL,
  payload: error,
})

export const getBrandsStatus = (authtoken, status) => ({
  type: GET_BRANDS_STATUS,
  payload: { authtoken, status }
})

export const getBrandsStatusSuccess = data => ({
  type: GET_BRANDS_STATUS_SUCCESS,
  payload: {data},
})

export const getBrandsStatusFail = error => ({
  type: GET_BRANDS_STATUS_FAIL,
  payload: error,
})

